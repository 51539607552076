import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }

const Statistics = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/cmn/getCountReport';
    const allListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';
    
    const [filters, setFilters] = useState({})

    const [years, setYears] = useState([])
    const [allDisasters, setAllDisasters] = useState([])
    const [disasters, setDisasters] = useState([])
    
    const [parameters, setParameters] = useState({})

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);

    useEffect(() => {
        if (allListFetchResponse)
        {
            const years = []
            const disasters = []
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt)
                {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt)
                {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }

                if (value?.name)
                {
                    if (!disasters.find(disaster => disaster.value == value.disasterCd)) disasters.push({ value: value.disasterCd, label: value.name, year: value?.startDt?.substring(0, 4) })
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({value: value, label: value})))
            setAllDisasters(disasters)
        }
    }, [allListFetchResponse])

    useEffect(() => {
        if (filters?.year)
        {
            setDisasters(allDisasters.filter(value => value.year == filters?.year))
        }
        else
        {
            setDisasters([])
        }
    }, [filters?.year])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({...current, ...filters}))
    }

    const initializeFilter = () => {
        setFilters({});
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">System management</h3>
                <Link to={'/statistics'} className="sidebar-link active">Statistics</Link>
                <Link to={'/user-management'} className="sidebar-link">User management</Link>
                <Link to={'/disaster-management'} className="sidebar-link">Disaster management</Link>
                <Link to={'/notices-news'} className="sidebar-link">Notices/News</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Statistics</h3>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Select Year/Disasters</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'Select year'}
                                            value={filters?.year ? {label: filters?.year} : null}
                                            onChange={(e) => setFilters(current => ({...current, year: e.value}))}
                                            isSearchable={false}
                                            options={years}
                                        />
                                    </div>
                                    <div className="col-lg-9">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'All disasters'}
                                            value={filters?.disasterCd ? disasters.find(value => value.value == filters.disasterCd) ?? null : null}
                                            onChange={(e) => setFilters(current => ({...current, disasterCd: e.value}))}
                                            isSearchable={false}
                                            options={disasters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                        </div>
                    </form>
                    
                    <p className="stats-heading">Victims</p>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="white-rounded-container stats-box mb-20px">
                                <p className="stats-title">Number of victims affected</p>
                                <p className="stats-value">{fetchIsLoading ? 'Loading...' : (fetchResponse?.resultObject?.cntVictim ?? '-')}</p>
                            </div>
                        </div>
                    </div>
                    
                    <p className="stats-heading">Workforce</p>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="white-rounded-container stats-box mb-20px">
                                <p className="stats-title">Committed officials</p>
                                <p className="stats-value">{fetchIsLoading ? 'Loading...' : (fetchResponse?.resultObject?.cntAdmin ?? '-')}</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="white-rounded-container stats-box mb-20px">
                                <p className="stats-title">Committed volunteers</p>
                                <p className="stats-value">{fetchIsLoading ? 'Loading...' : (fetchResponse?.resultObject?.cntVolunteer ?? '-')}</p>
                            </div>
                        </div>
                    </div>
                    
                    <p className="stats-heading">Relief supplies</p>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="white-rounded-container stats-box mb-20px">
                                <p className="stats-title">Number of relief items dispersed</p>
                                <p className="stats-value">{fetchIsLoading ? 'Loading...' : (fetchResponse?.resultObject?.cntSply ?? '-')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default Statistics;
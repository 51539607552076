import React, { useState, useEffect } from 'react';
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoClose, IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link, useParams } from "react-router-dom";
import useFetch from "../useFetch";
import { FormControl, Modal } from "react-bootstrap";
import { MdLocationSearching } from "react-icons/md";
import { FaAngry, FaMapMarkerAlt, FaPhoneAlt, FaSmile, FaUser } from "react-icons/fa";
import { FaFaceMeh } from "react-icons/fa6";
import { IoMdGlobe } from "react-icons/io";

const ShelterNoBelongDetails = () => {

    const [items, setItems] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }])
    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)
    const { id } = useParams();
    const [fetchResponse, setFetchResponse] = React.useState(null);
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);
    const [notices, setNotices] = useState([]);


    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getFAC?facilityId=' + id;
    const noticesApiUrl = process.env.REACT_APP_BASE_API_URL + '/noti/getNoticeLst?reqPageNo=1&reqPageSize=4&searchKeyword=&searchType=0';


    useEffect(() => {
        if (!apiUrl) return;

        setFetchIsLoading(true);
        setFetchError(null);

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                setFetchResponse(data);
                setFetchIsLoading(false);
            })
            .catch(error => {
                setFetchError(error.message);
                setFetchIsLoading(false);
            });
    }, [apiUrl]);

    useEffect(() => {
        // Fetch notices
        fetch(noticesApiUrl)
            .then(response => response.json())
            .then(data => {
                setNotices(data.resultList);
            })
            .catch(error => {
                // Handle error if needed
                console.error('Error fetching notices:', error);
            });
    }, [noticesApiUrl]);


    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }

    if (!fetchResponse) {
        return null;
    }

    const facilityData = fetchResponse.resultObject;



    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Shelter information</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/close.svg" />
                    </div>
                </div>
            </div>

            <div className="content-container flex-fill pt-0 pb-0">
                <img src="https://picsum.photos/500/200" className="img-fluid shelter-details-cover" />
                <div className="overlap-box">
                    <div className="d-flex align-items-center justify-content-center mb-1">
                        <p className="fw-bold fs-l mb-0">{facilityData.name}</p>
                        <Link to={"/shelter-admin-inquiry-details-belong-1-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Copy</Link>
                    </div>
                    <p className="mb-0">Acceptable Area: {facilityData.acceptableArea} </p>
                    <p className="mb-0">Maximum Capacity: {facilityData.maxCapacity} <span className="text-gray-02">|</span> <span className="face-red"><FaAngry /></span> Very bad</p>

                    <div className="mobile-section-separator" />

                    <div className="form-heading mb-0"><h5>Shelter information</h5></div>
                    <div className="d-flex align-items-center my-3">
                        <FaMapMarkerAlt size="18px" />
                        <p className="mb-0 ms-2">{facilityData.addr}</p>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <FaPhoneAlt size="18px" />
                        <p className="mb-0 ms-2">{facilityData.telno}</p>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <FaUser size="18px" />
                        <p className="mb-0 ms-2">{facilityData.currentVictimCount}</p>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <IoMdGlobe size="18px" />
                        <p className="mb-0 ms-2">{facilityData.involvedCount}</p>
                    </div>

                    <div className="mobile-section-separator" />

                    <div className="form-heading mb-0"><h5>Notice</h5></div>
                    {Array.isArray(notices) && notices.length > 0 && (
                        <div className="mobile-card border-gray-02 mb-12px">
                            <Link to={`/shelter-notice-details-no-belong-m/${notices[0].noticeId}`} className="plain-link">
                                <p className="mb-2 text-truncate-2">{notices[0].subject}</p>
                            </Link>
                            <p className="text-gray-04 fs-xxs mb-0">
                                {new Date(notices[0].createDt).toLocaleDateString('en-US')} <span className="notice-news-tag-orange">N</span>
                            </p>
                        </div>
                    )}
                    <button type="button" className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center"><Link to={'/shelter-notices-no-belong-m'}>View notice page</Link></button>
                </div>
            </div>
        </div>
    );
}

export default ShelterNoBelongDetails;
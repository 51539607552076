import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { TbQrcode } from "react-icons/tb";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { store } from "./redux/store";
import { removeUser } from "./redux/userSlice";

const MenuMobile = ({setIsVisibleMenu=null}) => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    const handleLogout = (e) => {
        e.preventDefault()
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/logout?userId='+user?.userId, {
            method: 'POST',
            // headers: {
            //     "Authorization": "Bearer "+localStorage.getItem("token")
            // }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }
            
            return response.json();
        })
        store.dispatch(removeUser());
        navigate('/login-m');
    }

    const qrUrl = user?.type == 'victim' ? '/qr-victim-m' : '/qr-admin-m'
    const shelterUrl = user?.facilityId ? (user?.type == 'victim' ? '/shelter-victim-belong-m' : '/shelter-admin-belong-m') : '/dashboard-m'
    const profileUrl = user?.type == 'victim' ? '/edit-information-victim' : (user?.type == 'admin' ? '/edit-information-admin' : '/edit-information-volunteer')
    
    return (
        <div className="d-flex flex-column min-vh-100 menu-mobile">
            <div className="sticky-top">
                <div className="bg-white d-flex container py-4">
                    <div className="flex-fill">
                        <h4>{user?.userName}</h4>
                        <h4>Gil-dong Hong</h4>
                        <p className="fs-xs mb-0">Ulsan high school</p>
                        <p className="fs-xs text-gray-04 mb-0">15 test, test, test, test, Korea</p>
                    </div>
                    <IoCloseOutline onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(false)}} size="32px" />
                </div>
            </div>
            
            <div className="content-container container flex-fill py-3">
                <Link onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(false)}} to={qrUrl} className="mobile-card menu-item mt-0 plain-link">
                    <div className="menu-icon">
                        <TbQrcode size="20px" />
                    </div>
                    <div className="menu-text">QR Code</div>
                </Link>
                <Link onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(false)}} to="/shelter-list-no-belong-m" className="mobile-card menu-item mt-3 plain-link">
                    <div className="menu-icon">
                        <FaMapMarkerAlt size="20px" />
                    </div>
                    <div className="menu-text">Search Shelter</div>
                </Link>
                <Link onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(false)}} to={shelterUrl} className="mobile-card menu-item mt-3 plain-link">
                    <div className="menu-icon">
                        <AiFillHome size="20px" />
                    </div>
                    <div className="menu-text">My Shelter</div>
                </Link>
                <Link onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(false)}} to="/find-missing-children-m" className="mobile-card menu-item mt-3 plain-link">
                    <div className="menu-icon">
                        <FaUser size="20px" />
                    </div>
                    <div className="menu-text">Missing Child</div>
                </Link>
                <Link onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(false)}} to={profileUrl} className="mobile-card menu-item mt-3 plain-link">
                    <div className="menu-icon">
                        <FiEdit size="20px" />
                    </div>
                    <div className="menu-text">Edit Information</div>
                </Link>
                <div onClick={handleLogout} className="fw-bold text-gray-04 text-decoration-underline mt-3 d-inline-block">LOG OUT</div>
            </div>
        </div>
    );
}
 
export default MenuMobile;
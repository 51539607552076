import { useEffect, useRef, useState } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";

const WarehouseRegister = () => {
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [type, setType] = useState(null)
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [coordinateX, setCoordinateX] = useState('')
    const [coordinateY, setCoordinateY] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [deptIncharge, setDeptIncharge] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [faxNumber, setFaxNumber] = useState('')
    const [repName, setRepName] = useState('')
    const [repPhoneNumber, setRepPhoneNumber] = useState('')
    const [ownerName, setOwnerName] = useState('')
    const [ownerPhoneNumber, setOwnerPhoneNumber] = useState('')

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])
    
    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
        ApiHelper.loadDropdown('groupCode=D003&codeDepth=1', setFacilityTypes)
    }, [])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!zipCode)
        {
            setError('Enter choose an address')
            return
        }

        setIsLoadingSubmit(true)
        
        var formData = {
            name: name,
            classCd: type?.value,
            typeCd: "01",
            zipCd : zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            area1Cd: area1?.value,
            area2Cd: area2?.value,
            lat: latitude,
            lot: longitude,
            deptCharge: deptIncharge,
            acceptableArea: 0,
            maxCapacity: 0,
            telno: phoneNumber,
            faxno: faxNumber,
            reprMgrTelno: repPhoneNumber,
            reprMgrName: repName,
            ownerTelno: ownerPhoneNumber,
            ownerName: ownerName,
            currentVictimCount: 0,
            involvedCount: 0,
            xcord: coordinateX,
            ycord: coordinateY,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/regFAC', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setName('')
                setType(null)
                setZipCode('')
                setMainAddress('')
                setDetailedAddress('')
                setArea1(null)
                setArea2(null)
                setCoordinateX('')
                setCoordinateY('')
                setLatitude('')
                setLongitude('')
                setDeptIncharge('')
                setPhoneNumber('')
                setFaxNumber('')
                setRepName('')
                setRepPhoneNumber('')
                setOwnerName('')
                setOwnerPhoneNumber('')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Relief supplies</h3>
                <Link to={'/inventory-management'} className="sidebar-link">Inventory management</Link>
                <Link to={'/warehouse-management'} className="sidebar-link active">Warehouse management</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">Incoming supplies</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">Outgoing supplies</Link>
                <Link to={'/requests-for-supplies'} className="sidebar-link">Requests for supplies</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Warehouse registration</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoadingSubmit ? 'disabled' : ''}`}>Register</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>Detailed Information</h5></div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Warehouse name<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-7">
                                            <FormControl
                                                onChange={e => setName(e.target.value)}
                                                value={name}
                                                placeholder="Please enter a name"
                                                required={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Type<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-7">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'Select'}
                                                value={type ?? null}
                                                onChange={(e) => setType(e)}
                                                options={facilityTypes}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label align-self-center">Address<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-7">
                                            <FormControl
                                                value={zipCode}
                                                placeholder="Please choose address"
                                                disabled={true} />
                                        </div>
                                        <div className="dms-input-5">
                                            <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-12">
                                            <FormControl
                                                value={mainAddress}
                                                placeholder="Please choose address"
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-12">
                                            <FormControl
                                                onChange={e => setDetailedAddress(e.target.value)}
                                                value={detailedAddress}
                                                placeholder="Enter detailed address"
                                                required={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Location</div>
                            <div className="dms-input-row">
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'Select Province'}
                                        value={area1 ?? null}
                                        onChange={(e) => setArea1(e)}
                                        options={areas1}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'Select District'}
                                        value={area2 ?? null}
                                        onChange={(e) => setArea2(e)}
                                        options={areas2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Coordinates</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setCoordinateX(e.target.value)}
                                        value={coordinateX}
                                        placeholder="X: 129308.1283" />
                                </div>
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setCoordinateY(e.target.value)}
                                        value={coordinateY}
                                        placeholder="Y: 129308.1283" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Latitude/Longitude</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setLatitude(e.target.value)}
                                        value={latitude}
                                        placeholder="Latitude: 12.1283" />
                                </div>
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setLongitude(e.target.value)}
                                        value={longitude}
                                        placeholder="Longitude: 129.1283" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Responsible department</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setDeptIncharge(e.target.value)}
                                        value={deptIncharge}
                                        placeholder="Please enter a name" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Telephone</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        value={phoneNumber}
                                        placeholder="Please enter phone number" />
                                </div>
                                <div className="dms-input-2 d-flex">
                                    <div className="dms-form-label align-self-center">Fax Number</div>
                                </div>
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setFaxNumber(e.target.value)}
                                        value={faxNumber}
                                        placeholder="Please enter fax number" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Principal officer<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setRepName(e.target.value)}
                                        value={repName}
                                        placeholder="Please enter a name"
                                        required={true} />
                                </div>
                                <div className="dms-input-2 d-flex">
                                    <div className="dms-form-label align-self-center">Principal officer phone number</div>
                                </div>
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setRepPhoneNumber(e.target.value)}
                                        value={repPhoneNumber}
                                        placeholder="Please enter phone number" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Facility officer<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setOwnerName(e.target.value)}
                                        value={ownerName}
                                        placeholder="Please enter a name"
                                        required={true} />
                                </div>
                                <div className="dms-input-2 d-flex">
                                    <div className="dms-form-label align-self-center">Facility officer phone number<span className="label-ast">*</span></div>
                                </div>
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setOwnerPhoneNumber(e.target.value)}
                                        value={ownerPhoneNumber}
                                        placeholder="Please enter phone number"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                    
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />
        </div>
    );
}
 
export default WarehouseRegister;
import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { IoClose } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useSelector } from "react-redux";

const QrScanMealMobile = () => {
    const { user } = useSelector(state => state.user);

    // const [isVisibleAlreadyReceived, setIsVisibleAlreadyReceived] = useState(false)
    // const [isVisibleAlreadyEaten, setIsVisibleAlreadyEaten] = useState(false)
    
    const [isLoading, setIsLoading] = useState(null);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    
    const handleScan = (code) => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)

        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMInfo?userId='+code)
        .then(response => {
            if (!response.ok) throw Error('Failed to load data');
            return response.json();
        })
        .then(data => {
            if (data?.resultObject?.userId)
            {
                var formData = {
                    userId: code,
                    facilityId: user?.facilityId ?? 1,
                }
                
                fetch(process.env.REACT_APP_BASE_API_URL+'/victim/regVCTMMealHist', { 
                    method: 'POST',
                    body: JSON.stringify(formData),
                    headers: { 
                        "Content-Type": "application/json",
                        //"Authorization": "Bearer "+localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw Error('Error in submitting data');
                    }
        
                    return response.json();
                })
                .then(data => {
                    if (data.responseCode === 0)
                    {
                        setSuccess(data.responseMessage ?? 'Success')
                    }
                    else
                    {
                        setError(data.responseMessage ?? 'Error in submitting data');
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    setError(error.message);
                    setIsLoading(false);
                })
            }
            else
            {
                setError('Victim does not belong to any shelter')
                setIsLoading(false);
            }
        })
        .catch(error => {
            setError('Error in submitting data')
            setIsLoading(false);
        })
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="content-container px-12px flex-fill pt-4 pb-2 bg-gray-04">
                <Link to="/qr-admin-m" className="text-end text-white">
                    <IoClose size="30px" />
                </Link>
                <div>
                    <h5 className="text-white fs-xs mb-0 mt-4 text-center">When the QR code is recognized, it is automatically photographed</h5>
                    <div className={`qr-scanner-container ${isLoading ? 'loading' : ''}`}>
                        <img src="/images/qr-loader.svg" className="qr-loader" />
                        <QrScanner
                            containerStyle={{ 
                                marginTop: '16px',
                                borderRadius: '8px',
                                //height: window.innerWidth - 24, 
                                //width: window.innerWidth - 24
                            }}
                            stopDecoding={isLoading}
                            scanDelay={3000}
                            onDecode={(result) => handleScan(result)}
                            onError={(error) => setError(error?.message)}
                        />
                    </div>
                    {/* {scannedCode ? <h5 className="text-white fs-xs mb-0 mt-4 text-center">Code: {scannedCode}</h5> : null} */}
                    {error ? <h5 className="text-white fs-xs mb-0 mt-4 text-center">Error: {error}</h5> : null}
                </div>
            </div>

            {/* <Modal className="mobile-modal-2" show={isVisibleAlreadyReceived} onHide={() => setIsVisibleAlreadyReceived(false)} centered>
                <Modal.Body>
                    <p>This is a recipient who has already received. Do you want to give it again?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleAlreadyReceived(false)} className="dms-btn dms-btn-s">No</button>
                    <button onClick={() => setIsVisibleAlreadyReceived(false)} className="dms-btn dms-btn-s">Yes</button>
                </Modal.Footer>
            </Modal>

            <Modal className="mobile-modal-2" show={isVisibleAlreadyEaten} onHide={() => setIsVisibleAlreadyEaten(false)} centered>
                <Modal.Body>
                    <p>This is a recipient who has already eaten. Do you want to record it?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleAlreadyEaten(false)} className="dms-btn dms-btn-s">No</button>
                    <button onClick={() => setIsVisibleAlreadyEaten(false)} className="dms-btn dms-btn-s">Yes</button>
                </Modal.Footer>
            </Modal> */}

            <Modal className="mobile-modal-2" show={success!==null} onHide={() => setSuccess(null)} centered>
                <Modal.Body>
                    <p>{success}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setSuccess(null)} className="dms-btn dms-btn-s w-100">OK</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export default QrScanMealMobile;
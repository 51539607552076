import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";
import { MdLocationSearching } from "react-icons/md";
import { FaAngry, FaSmile } from "react-icons/fa";
import { FaFaceMeh } from "react-icons/fa6";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const ShelterNoBelongList = () => {
    const { user } = useSelector(state => state.user);
    
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getFACLst';

    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [facilityType, setFacilityType] = useState(null)
    
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])

    const [parameters, setParameters] = useState({
        typeCd: '02',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleLoadMore = () => {
        setParameters(current => ({...current, reqPageSize: current.reqPageSize+10}))
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setIsVisibleFilterModal(false)

        setParameters(current => ({
            ...current, 
            reqPageSize: 10,
            ...filters,
            ...(area1?.value ? {area1Cd: area1.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
            ...(facilityType?.value ? {facilityType: facilityType.value} : null),
        }))
    }

    const initializeFilter = () => {
        setIsVisibleFilterModal(false)
        setFilters({});
        setArea2(null)
        setFacilityType(null)
        setParameters(current => ({typeCd: '02', reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
        ApiHelper.loadDropdown('groupCode=D003&codeDepth=1', setFacilityTypes)
    }, [])

    useEffect(() => {
        if(areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])
    
    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <Link to="/dashboard-m" className="mobile-navbar-left">
                        <img src="/images/icons/back.svg" />
                    </Link>
                    <div className="mobile-navbar-title">View as a list</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/home.svg" />
                    </div>
                </div>
            </div>

            <div className="content-container flex-fill pt-0 pb-2">
                <div className="mobile-filter-box d-flex">
                    <div onClick={() => setIsVisibleFilterModal(true)} className={`filter-item ${facilityType?.value ? 'active' : ''}`}>
                        <span>Category</span>
                        <IoChevronDown size="18px" />
                    </div>
                    <div onClick={() => setIsVisibleFilterModal(true)} className={`filter-item ${area2?.value ? 'active' : ''}`}>
                        <span>Region/Shelter</span>
                        <IoChevronDown size="18px" />
                    </div>
                    <div onClick={() => setIsVisibleFilterModal(true)} className={`filter-item ${filters?.name ? 'active' : ''}`}>
                        <span>Shelter name</span>
                        <IoChevronDown size="18px" />
                    </div>
                </div>
                <div className="container pb-5">
                    <div className="mobile-card">
                        <Link to={'/shelter-victim-notices-belong-m'} className="d-flex align-items-center plain-link">
                            <p className="flex-fill mb-0 text-truncate">A long text long text long text.</p>

                            <div className="text-green me-2"><MdLocationSearching size="24px" /></div>
                        </Link>
                    </div>
                    {items.map(item => (
                        <div key={item.facilityId} className="mobile-card">
                            <div className="d-flex align-items-center mb-2">
                                <p className="mobile-card-title d-block fw-bold text-black">{item.name} <span className="face-yellow"><FaSmile /></span></p>
                                <Link to={`/shelter-details-no-belong-m/${item.facilityId}`} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                            </div>
                            <p className="mobile-card-text"> {item.addr} </p>
                            <p className="mobile-card-text">Maximum capacity: {item.maxCapacity} </p>
                        </div>
                    ))}

                    {fetchIsLoading || (totalCount !== null && totalCount > parameters?.reqPageSize) ? <button type="button" onClick={handleLoadMore} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">{fetchIsLoading ? 'Loading data' : 'View more ('+parameters?.reqPageSize+'/'+totalCount+')'}</button> : null}

                    <div className="map-floating-btn"><Link to={'/dashboard-m'} className="plain-link">View on map</Link></div>
                </div>
            </div>

            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />

            <Modal className="modal-search-address modal-mobile-filter" fullscreen={true} show={isVisibleFilterModal} onHide={() => setIsVisibleFilterModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Search Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex pt-0">
                    <div className="content-container container flex-fill">
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Category</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                isSearchable={false}
                                placeholder={'All'}
                                value={facilityType ?? null}
                                onChange={(e) => setFacilityType(e)}
                                options={facilityTypes}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Name of region/shelter</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                isSearchable={false}
                                placeholder={'Select Province'}
                                value={area1 ?? null}
                                onChange={(e) => setArea1(e)}
                                options={areas1}
                            />
                        </div>
                        <div className="form-group">
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                isSearchable={false}
                                placeholder={'Entire city'}
                                value={area2 ?? null}
                                onChange={(e) => setArea2(e)}
                                options={areas2}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Shelter name</div>
                            <FormControl
                                value={filters?.name ?? ''}
                                onChange={e => setFilters(current => ({...current, name: e.target.value}))}
                                placeholder="Please enter a name" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0">
                    <div className="sticky-bottom mobile-bottom-btn-container m-0">
                        <div className="row g-0">
                            <div className="col-6">
                                <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-l dms-btn-filled-white disabled1">Initialize</button>
                            </div>
                            <div className="col-6">
                                <button type="button" onClick={handleFilterSubmit} className="dms-btn dms-btn-l dms-btn-filled">Search</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ShelterNoBelongList;
import { useRef, useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { TbQrcode } from "react-icons/tb";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const VolunteerDetails = () => {
    const uploadFileRef = useRef()

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [isVisibleMoveShelter, setIsVisibleMoveShelter] = useState(false)
    const [isVisibleGettingInShelter, setIsVisibleGettingInShelter] = useState(false)
    const [isVisibleGettingOutShelter, setIsVisibleGettingOutShelter] = useState(false)
    const [image, setImage] = useState(false)

    const handleImageChange = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            setImage(reader.result)
            if (uploadFileRef?.current)
            {
                uploadFileRef.current.value = '';
            }
        }
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Volunteers</h3>
                <Link to={'/volunteer-management'} className="sidebar-link active">Volunteers management</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">Volunteers assignment</Link>
                <Link to={'/volunteer-requests'} className="sidebar-link">Requests for volunteers</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">View volunteer details</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">Modified: 2023-07-23 10:50:00</p>
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">To List</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline mr"><TbQrcode  size="16px" /> QR Print</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Modify</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Personal information</h5></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="Test" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Membership number</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="Test" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">ID<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="Test" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label align-self-center">Password<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-12">
                                            <FormControl placeholder="8 characters including alphanumeric and special characters" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-12">
                                            <FormControl placeholder="Confirm your password" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Date of birth<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="Test" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label align-self-center">Address<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter value" defaultValue="Test Test Test" disabled />
                                        </div>
                                        <div className="dms-input-6">
                                            <button className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-12">
                                            <FormControl placeholder="Please enter value" defaultValue="Test Test Test Test Test Test" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-12">
                                            <FormControl placeholder="Please enter value" defaultValue="Test Test Test" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Phone Number<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="0123-412312-4123" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Email</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="0123-412312-4123" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Registration Center</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-6">
                                            <FormControl placeholder="Please enter a name" defaultValue="0123-412312-4123" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="picture-preview-box">
                                    {image ? <img src={image} /> : <span>NO PICTURE</span>}
                                    <div className="overlay">
                                        <label htmlFor="upload-file" className="dms-btn dms-btn-s dms-btn-outline align-self-center">
                                            {image ? 'Photo change' : 'Upload'}
                                            <input ref={uploadFileRef} type="file" accept="image/jpg,image/jpeg,image/png" id="upload-file" hidden onChange={handleImageChange}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading d-flex justify-content-between align-items-center">
                            <h5>Current Shelter</h5>
                            <button type="button" onClick={() => setIsVisibleMoveShelter(true)} className="dms-btn dms-btn-xs dms-btn-outline">Move Shelter</button>
                        </div>

                        <table className="table dms-table-1">
                            <thead>
                                <tr>
                                    <th>City/Province</th>
                                    <th>District</th>
                                    <th>Shelter name</th>
                                    <th>Role</th>
                                    <th>Admission time</th>
                                    <th>Discharge time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={4}>There is no shelter that the victim entered.</td>
                                    <td><button onClick={() => setIsVisibleGettingInShelter(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">Getting in</button></td>
                                    <td><button className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3 disabled">Getting out</button></td>
                                </tr>
                                <tr>
                                    <td>Test Test</td>
                                    <td>Test</td>
                                    <td>Test Test Test</td>
                                    <td>Mill</td>
                                    <td>2023-07-23 11:21:23</td>
                                    <td><button onClick={() => setIsVisibleGettingOutShelter(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">Getting out</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Shelter commitment history</h5></div>
                        
                        <div className="row mb-20px">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Select Year/Disasters</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'Select year'}
                                            isSearchable={false}
                                            options={[
                                                { value: '2023', label: '2023' },
                                                { value: '2022', label: '2022' },
                                                { value: '2021', label: '2021' }
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-9">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'All Disasters'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All Disasters', label: 'All Disasters' },
                                                { value: '2022', label: '2022' },
                                                { value: '2021', label: '2021' }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table className="table dms-table-1">
                            <thead>
                                <tr>
                                    <th>City/Province</th>
                                    <th>District</th>
                                    <th>Relief station address</th>
                                    <th>Commitment shelter</th>
                                    <th>Role</th>
                                    <th>Date of admission</th>
                                    <th>Date of discharge</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(value => (
                                    <tr key={value.id}>
                                        <td>Test Test</td>
                                        <td>Test Test</td>
                                        <td>Test</td>
                                        <td>Test Test Test</td>
                                        <td>Test Test Test</td>
                                        <td>2023-07-23 11:21:23</td>
                                        <td>2023-07-23 11:21:23</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        
                        <ReactPaginate
                            //onPageChange={handlePageClick}
                            forcePage={0}
                            pageCount={5}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    </div>
                </div>
                    
                <Footer />
            </div>

            <Modal size="lg" show={isVisibleMoveShelter} onHide={() => setIsVisibleMoveShelter(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Move Shelter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-lg-2 d-flex align-items-center">
                            <div className="dms-filter-label">Move shelter</div>
                        </div>
                        <div className="col-lg-10">
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Ulsen City'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Ulsen City', label: 'Ulsen City' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Entire City'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Entire city', label: 'Entire city' },
                                            { value: 'Area 1', label: 'Area 1' },
                                            { value: 'Area 2', label: 'Area 2' },
                                            { value: 'Area 3', label: 'Area 3' },
                                        ]}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'All shelter'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'All shelter', label: 'All shelter' },
                                            { value: 'Shelter 1', label: 'Shelter 1' },
                                            { value: 'Shelter 2', label: 'Shelter 2' },
                                            { value: 'Shelter 3', label: 'Shelter 3' },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleMoveShelter(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={() => setIsVisibleMoveShelter(false)} className="dms-btn dms-btn-xs dms-btn-filled">OK</button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={isVisibleGettingInShelter} onHide={() => setIsVisibleGettingInShelter(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Getting in shelter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-lg-2 d-flex align-items-center">
                            <div className="dms-filter-label">Relocate shelter</div>
                        </div>
                        <div className="col-lg-10">
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Ulsen City'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Ulsen City', label: 'Ulsen City' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Entire City'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Entire city', label: 'Entire city' },
                                            { value: 'Area 1', label: 'Area 1' },
                                            { value: 'Area 2', label: 'Area 2' },
                                            { value: 'Area 3', label: 'Area 3' },
                                        ]}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'All shelter'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'All shelter', label: 'All shelter' },
                                            { value: 'Shelter 1', label: 'Shelter 1' },
                                            { value: 'Shelter 2', label: 'Shelter 2' },
                                            { value: 'Shelter 3', label: 'Shelter 3' },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-2 d-flex align-items-center">
                            <div className="dms-filter-label">Role</div>
                        </div>
                        <div className="col-lg-10">
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Select role'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Select role', label: 'Select role'},
                                            { value: 'Female', label: 'Female'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleGettingInShelter(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={() => setIsVisibleGettingInShelter(false)} className="dms-btn dms-btn-xs dms-btn-filled">OK</button>
                </Modal.Footer>
            </Modal>

            <Modal show={isVisibleGettingOutShelter} onHide={() => setIsVisibleGettingOutShelter(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Getting out shelter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure to getting out the target?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleGettingOutShelter(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={() => setIsVisibleGettingOutShelter(false)} className="dms-btn dms-btn-xs dms-btn-filled">OK</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export default VolunteerDetails;
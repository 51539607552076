import { useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const AdminShelterBelongInquiries = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="mobile-navbar-left">
                        <img src="/images/icons/back.svg" />
                    </div>
                    <div className="mobile-navbar-title">Shelter inquiry board</div>
                    <Link to={'/shelter-victim-register-inquiry-belong-m'} className="mobile-navbar-right plain-link">
                        <img src="/images/icons/plus.svg" />
                    </Link>
                </div>
            </div>
            
            <div className="content-container container flex-fill pt-0 pb-4">
                {/* <div>
                    <Link to={'/shelter-victim-register-inquiry-belong-m'} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center"><GoPlusCircle size="22px" />New Inquiry</Link>
                </div> */}
                <p className="text-gray-03 fs-xs mb-n2 mt-3">March, 2023</p>
                <div className="mobile-card">
                    <div className="d-flex align-items-center mb-2">
                        <div>
                            <p className={`mobile-card-title d-block text-gray-04`}>[Waiting]</p>
                            <p className="mobile-card-title d-block fw-bold text-black">Requesting relief supplies</p>
                        </div>
                        <Link to={"/shelter-admin-inquiry-details-belong-1-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                    </div>
                    <p className="mb-2">A long text long text long text. A long text long text long text. A long text long text long text.</p>
                    <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00 | Test test</p>
                </div>
                <div className="mobile-card">
                    <div className="d-flex align-items-center mb-2">
                        <div>
                            <p className={`mobile-card-title d-block text-gray-04`}>[Waiting]</p>
                            <p className="mobile-card-title d-block fw-bold text-black">Requesting relief supplies</p>
                        </div>
                        <Link to={"/shelter-admin-inquiry-details-belong-1-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                    </div>
                    <p className="mb-2">A long text long text long text. A long text long text long text. A long text long text long text.</p>
                    <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00 | Test test</p>
                </div>
                <p className="text-gray-03 fs-xs mb-n2 mt-3">March, 2023</p>
                <div className="mobile-card">
                    <div className="d-flex align-items-center mb-2">
                        <div>
                            <p className={`mobile-card-title d-block text-green`}>[Answer]</p>
                            <p className="mobile-card-title d-block fw-bold text-black">Requesting relief supplies</p>
                        </div>
                        <Link to={"/shelter-admin-inquiry-details-belong-2-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                    </div>
                    <p className="mb-2">A long text long text long text. A long text long text long text. A long text long text long text.</p>
                    <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00 | Test test</p>
                </div>
                <div className="mobile-card">
                    <div className="d-flex align-items-center mb-2">
                        <div>
                            <p className={`mobile-card-title d-block text-green`}>[Answer]</p>
                            <p className="mobile-card-title d-block fw-bold text-black">Requesting relief supplies</p>
                        </div>
                        <Link to={"/shelter-admin-inquiry-details-belong-2-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                    </div>
                    <p className="mb-2">A long text long text long text. A long text long text long text. A long text long text long text.</p>
                    <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00 | Test test</p>
                </div>
                <div className="mobile-card">
                    <div className="d-flex align-items-center mb-2">
                        <div>
                            <p className={`mobile-card-title d-block text-green`}>[Answer]</p>
                            <p className="mobile-card-title d-block fw-bold text-black">Requesting relief supplies</p>
                        </div>
                        <Link to={"/shelter-admin-inquiry-details-belong-2-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                    </div>
                    <p className="mb-2">A long text long text long text. A long text long text long text. A long text long text long text.</p>
                    <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00 | Test test</p>
                </div>

                <button type="button" className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">View more (1/166)</button>
            </div>

            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}
 
export default AdminShelterBelongInquiries;
import { useState, useEffect } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";

const keywordOptions = [
    { value: '0', label: 'All' },
    { value: '1', label: 'Subject' },
    { value: '2', label: 'Contents' },
    { value: '3', label: 'Writer' },
]

const NoticesNews = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/noti/getNoticeLst';

    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [parameters, setParameters] = useState({
        searchType: '0',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse) 
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({...current, reqPageNo: 1, ...filters}))
    }

    const initializeFilter = () => {
        setFilters({});
        setParameters(current => ({searchType: '0', reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    };

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">System management</h3>
                <Link to={'/statistics'} className="sidebar-link">Statistics</Link>
                <Link to={'/user-management'} className="sidebar-link">User management</Link>
                <Link to={'/disaster-management'} className="sidebar-link">Disaster management</Link>
                <Link to={'/notices-news'} className="sidebar-link active">Notices/News</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Notices/News</h3>
                        <Link to="/notices-news-register" className="dms-btn dms-btn-s dms-btn-outline">Registration</Link>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Keyword Search</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm"
                                            placeholder={"All"}
                                            value={filters?.searchType ? keywordOptions.find(value => value.value == filters?.searchType) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, searchType: e.value }))}
                                            isSearchable={false}
                                            options={keywordOptions}
                                        />
                                    </div>
                                    <div className="col-lg-9">
                                        <FormControl 
                                            value={filters?.searchKeyword ?? ''}
                                            onChange={e => setFilters(current => ({...current, searchKeyword: e.target.value}))}
                                            placeholder="Search keyword" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                        </div>
                    </form>

                    <table className="table dms-table-seperate">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Post Title</th>
                                <th>Writer</th>
                                <th>When Published</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (

                                <tr key={item.noticeId}>
                                    <td>{'0000' + item.noticeId}</td>
                                    <td>
                                        <Link to={`/notices-news-preview/${item.noticeId}`} className="link-dark">
                                            {item.subject}
                                            <span className="notice-news-tag-orange">N</span>
                                        </Link>
                                    </td>
                                    <td>{item.writerName}</td>
                                    <td>{formatDateString(item.createDt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount / parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                            forcePage={Math.max(parameters.reqPageNo - 1, 0)}
                            pageCount={Math.ceil(totalCount / parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>

                <Footer />
            </div>

            <SaveExcelModal isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}

export default NoticesNews;
import { useRef, useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";

const VolunteerRegister = () => {
    const uploadFileRef = useRef()

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [image, setImage] = useState(false)

    const handleImageChange = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            setImage(reader.result)
            if (uploadFileRef?.current)
            {
                uploadFileRef.current.value = '';
            }
        }
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Volunteers</h3>
                <Link to={'/volunteer-management'} className="sidebar-link active">Volunteers management</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">Volunteers assignment</Link>
                <Link to={'/volunteer-requests'} className="sidebar-link">Requests for volunteers</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Register volunteer</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">To List</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Register</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Personal information</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="Test" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Membership number</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="Test" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">ID<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="Test" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">Password<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="8 characters including alphanumeric and special characters" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Confirm your password" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Date of birth<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="Test" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">Address<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter value" defaultValue="Test Test Test" disabled />
                                </div>
                                <div className="dms-input-4">
                                    <button className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Please enter value" defaultValue="Test Test Test Test Test Test" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Please enter value" defaultValue="Test Test Test" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Phone Number<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="0123-412312-4123" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Email</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="0123-412312-4123" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Registration Center</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" defaultValue="0123-412312-4123" disabled />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Thumbnail</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-2">Upload a photo</div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Please enter a name" />
                                    <div className="input-helper">Upload up to 1 images. Uploadable extensions: JPG, JPEG, PNG</div>
                                </div>
                                <div className="dms-input-4">
                                    <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />Find File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default VolunteerRegister;
import { useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const QrAdminMobile = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)
    
    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="bg-blue text-white container py-4">
                    <p className="fs-xxs mb-1">2023-08-12 12:12:00 outbreak</p>
                    <h4 className="mb-2">Typhoon warning for all of Ulsan</h4>
                    <p className="fs-xxs mb-0">Typhoon 6 kanun, northward from 10 days</p>
                </div>
            </div>
            
            <div className="content-container container flex-fill pb-2 d-flex flex-column justify-content-between">
                <div>
                    <div className="form-heading">
                        <h5>QR Scan</h5>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <Link to="/qr-scan-in-out" className="plain-link">
                                    <h5>Entering/leaving shelter</h5>
                                    <p className="text-gray-04 fs-xxs mb-0">Scan survivor QR codes to check in / out of the current shelter.</p>
                                </Link>
                            </div>
                            <div className="ms-2">
                                <Link to="/qr-scan-in-out" className="plain-link">
                                    <div className="bg-gray-01 rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                                        <IoChevronForward size="24px" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <Link to="/bringing-in-relief-supplies-m" className="plain-link">
                                    <h5>Bringing in relief supplies</h5>
                                    <p className="text-gray-04 fs-xxs mb-0">After scanning a relief item Qr code, scan the victim's QR code to complete the delivery.</p>
                                </Link>
                            </div>
                            <div className="ms-2">
                                <Link to="/bringing-in-relief-supplies-m" className="plain-link">
                                    <div className="bg-gray-01 rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                                        <IoChevronForward size="24px" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <Link to="/qr-scan-meal" className="plain-link">
                                    <h5>Meals</h5>
                                    <p className="text-gray-04 fs-xxs mb-0">After pressing the appropriate button, scan the survivor QR code.</p>
                                </Link>
                            </div>
                            <div className="ms-2">
                                <Link to="/qr-scan-meal" className="plain-link">
                                    <div className="bg-gray-01 rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                                        <IoChevronForward size="24px" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center mt-3 mb-2">It was received successfully.</div> */}
            </div>

            <FooterMobile user={user} active="QR Code" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}
 
export default QrAdminMobile;
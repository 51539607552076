import { AiFillHome } from "react-icons/ai";
import { IoMenu } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FooterMobile = ({user=null, active = null, isVisibleMenu=null, setIsVisibleMenu=null}) => {
    if (isVisibleMenu) return null

    const qrUrl = user?.type == 'victim' ? '/qr-victim-m' : '/qr-admin-m'
    const shelterUrl = user?.facilityId ? (user?.type == 'victim' ? '/shelter-victim-belong-m' : '/shelter-admin-belong-m') : '/dashboard-m'
    
    return (
        <div className="footer-mobile sticky-bottom mobile-bottom-btn-container">
            <Link to={qrUrl} className={`footer-item plain-link ${active == 'QR Code' ? 'active' : ''}`}>
                <img src={`/images/icons/nav-qr-${active == 'QR Code' ? 'on' : 'off'}.svg`} className="footer-icon" />
                <div className="footer-text">QR Code</div>
            </Link>
            <Link to={shelterUrl} className={`footer-item plain-link ${active == 'Shelter' ? 'active' : ''}`}>
                <img src={`/images/icons/nav-shelter-${active == 'Shelter' ? 'on' : 'off'}.svg`} className="footer-icon" />
                <div className="footer-text">Shelter</div>
            </Link>
            <div onClick={() => {if(setIsVisibleMenu) setIsVisibleMenu(current => !current)}} className={`footer-item plain-link ${active == 'Menu' ? 'active' : ''}`}>
                <img src={`/images/icons/nav-menu-off.svg`} className="footer-icon" />
                <div className="footer-text">Menu</div>
            </div>
        </div>
    )
}

export default FooterMobile;

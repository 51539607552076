import React, { useState, useEffect, useRef } from 'react';
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import { GoArrowLeft } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import { useSelector } from "react-redux";
import { store } from "../redux/store";
import { setUser } from "../redux/userSlice";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const EditInformationVolunteer = () => {
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isAdd1, setIsAdd1] = useState(false)
    const [isAdd2, setIsAdd2] = useState(false)
    const { user } = useSelector(state => state.user);
    const [userId, setUserId] = useState('');
    const [fetchResponse, setFetchResponse] = React.useState(null);
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);
    const [name, setName] = useState('');
    const [pwd, setPwd] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [telNo, setTelNo] = useState('');
    const [bday, setBday] = useState('');
    const [addr, setAddr] = useState('');
    const [addrDtl, setAddrDtl] = useState('');
    const [email, setEmail] = useState('');
    const [affiliationCd, setAffiliationCd] = useState('');
    const [zipCd, setZipCd] = useState('');
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [idx, setIdx] = useState('')
    const [vltrRegCenterName, setVltrRegCenterName] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [passwordError, setPasswordError] = useState(null)


    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/auth/getUser?userId=${user.userId}`;


    useEffect(() => {
        if (ValidatorHelper.validatePassword(pwd, setPasswordError)) {
            if (pwd != passwordConfirm) {
                setPasswordError('Both password must match')
            }
        }
    }, [pwd])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(pwd)) return
        if (pwd != passwordConfirm) {
            setPasswordError('Both password must match')
        }
        else {
            setPasswordError(null)
        }
    }, [passwordConfirm])


    useEffect(() => {
        if (!apiUrl) return;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setUserId(resultObject.userId);
                setName(resultObject.name);
                setIdx(resultObject.idx);
                setTelNo(resultObject.telNo);
                setBday(resultObject.bday);
                setAddr(resultObject.addr);
                setAddrDtl(resultObject.addrDtl);
                setZipCode(resultObject.zipCd);
                setMainAddress(resultObject.addr);
                setDetailedAddress('');
                setEmail(resultObject.email);
                setAffiliationCd(resultObject.affiliationCd);
                setZipCd(resultObject.zipCd);
                setVltrRegCenterName(resultObject.vltrRegCenterName);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl]);

    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        if (pwd != passwordConfirm) {
            setError('Both password must match')
            return
        }

        setIsLoadingSubmit(true);

        var formData = {
            userId: userId,
            name,
            bday,
            telNo,
            email,
            pwd,
            affiliationCd,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl,

        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/auth/updateUser', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            });
    };


    return (
        <div className="d-flex flex-column min-vh-100">
            <form onSubmit={handleSubmit} className="inner-container1">
                <div className="sticky-top">
                    <div className="mobile-navbar">
                        <div className="blank-icon" />
                        <div className="mobile-navbar-title">Edit information</div>
                        <div className="mobile-navbar-right">
                            <img src="/images/icons/close.svg" />
                        </div>
                    </div>
                </div>

                <div className="content-container container flex-fill pb-4">

                    <div className="form-heading"><h5>Basic information</h5></div>
                    <div>
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                            <FormControl
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Please enter a name" />
                        </div>

                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Membership Number<span className="label-ast">*</span></div>
                            <FormControl
                                value={idx}
                                placeholder="Please enter a name"
                                disabled={true} />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="dms-form-label align-self-center">ID<span className="label-ast">*</span></div>
                        <FormControl
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            placeholder="Please enter ID" />
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Password<span className="label-ast">*</span></div>
                        <FormControl
                            value={pwd}
                            required={true}
                            className={passwordError ? 'is-invalid' : ''}
                            onChange={(e) => setPwd(e.target.value)}
                            placeholder="8 characters including alphanumeric and special characters" />
                        <FormControl
                            value={passwordConfirm}
                            className={passwordError ? 'is-invalid' : ''}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            placeholder="Confirm your password" />
                        {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Birthday<span className="label-ast">*</span></div>
                        <FormControl
                            value={bday}
                            onChange={(e) => setBday(e.target.value)} placeholder="Please enter ID" />
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Address</div>
                        <div className="d-flex">
                            <div className="flex-fill me-2">
                                <FormControl
                                    // value={addr}
                                    // onChange={(e) => setAddr(e.target.value)}
                                    // placeholder="Please enter value" disabled
                                    value={zipCode}
                                    placeholder="Please choose address"
                                    disabled={true} />
                            </div>
                            <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find</button>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label"></div>
                        <FormControl
                            // placeholder="Please enter value" disabled 
                            value={mainAddress}
                            placeholder="Please choose address"
                            // onChange={(e) => setAddr(e.target.value)}

                            disabled={true} />
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label"></div>
                        <FormControl
                            value={addrDtl}
                            onChange={(e) => setAddrDtl(e.target.value)}
                            placeholder="Please enter value" />
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Call Number<span className="label-ast">*</span></div>
                        <FormControl
                            value={telNo}
                            onChange={(e) => setTelNo(e.target.value)}
                            placeholder="Please enter value" />
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Email address<span className="label-ast">*</span></div>
                        <FormControl
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Please enter value" />
                    </div>
                    <div className="form-group mb-0">
                        <div className="dms-form-label align-self-center">Registration center<span className="label-ast">*</span></div>
                        <FormControl
                            value={vltrRegCenterName}
                            placeholder="Please enter value"
                            disabled />
                    </div>

                </div >

                <div className="sticky-bottom mobile-bottom-btn-container">
                    <button type="submit" className="dms-btn dms-btn-l dms-btn-filled">Modify</button>
                </div>

            </form>


            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress}
                setIsVisible={setIsVisibleAddress}
                fullscreen={true} />
        </div >
    );
}

export default EditInformationVolunteer;
import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";
import useFetchWithParameters from "../useFetchWithParameters";
import { format } from "date-fns";

const VictimShelterBelongNotices = () => {
    const { user } = useSelector(state => state.user);
    
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/noti/getNoticeLst';
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    
    const [parameters, setParameters] = useState({
        facilityId: user.facilityId,
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleLoadMore = () => {
        setParameters(current => ({...current, reqPageSize: current.reqPageSize+10}))
    }

    var lastDate = null

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <Link to={'/shelter-victim-belong-m'} className="mobile-navbar-left plain-link">
                        <img src="/images/icons/back.svg" />
                    </Link>
                    <div className="mobile-navbar-title">Shelter notice</div>
                    <div className="blank-icon" />
                </div>
            </div>
            
            <div className="content-container container flex-fill pt-0 pb-4">
                {items.map(item => {
                    const newDate = format(new Date(item.createDt), 'MMMM, yyyy')
                    const dateToPrint = lastDate != newDate
                    lastDate = newDate
                    
                    return (
                        <div key={item.noticeId}>
                            {dateToPrint ? <p className="text-gray-03 fs-xs mb-n2 mt-3">{newDate}</p> : null}
                            <Link to={'/shelter-victim-notice-details-belong-m/'+item.noticeId} className="plain-link mobile-card d-block">
                                <p className="mb-2">{item.subject}
                                    {/* <span className="notice-news-tag-orange">N</span> */}
                                </p>
                                <p className="text-gray-03 fs-xxs mb-0">{item.updateDt ?? item.createDt}</p>
                            </Link>
                        </div>
                    )
                })}

                {fetchIsLoading || (totalCount !== null && totalCount > parameters?.reqPageSize) ? <button type="button" onClick={handleLoadMore} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">{fetchIsLoading ? 'Loading data' : 'View more ('+parameters?.reqPageSize+'/'+totalCount+')'}</button> : null}
            </div>

            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}
 
export default VictimShelterBelongNotices;
import React, { useState, useEffect, useRef } from 'react';
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import useFetchWithParameters from "../useFetchWithParameters";
import ApiHelper from "../Helpers/ApiHelper";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";



const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }
const optionsInOutStatus = [
    { value: 'IN', label: 'IN' },
    { value: 'OUT', label: 'OUT' },
]


const InventoryDetails = () => {
    const { id } = useParams();

    const [items, setItems] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false);
    const [isVisibleSaveExcel2, setIsVisibleSaveExcel2] = useState(false);
    const warehousesApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getFacSplyLst';

    const [warehousesParameters, setWarehousesParameters] = useState({
        splyId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const [parameters, setParameters] = useState({
        splyId:id,
        typeCd: '02',
        reqPageNo: 1,
        reqPageSize: 10,
        
    })

    const [warehouses, setWarehouses] = useState([])
    const [warehousesTotalCount, setWarehousesTotalCount] = useState(null);
    const [warehousesFilters, setWarehousesFilters] = useState({})

    const { fetchResponse: warehousesFetchResponse, fetchIsLoading: warehousesFetchIsLoading, fetchError: warehousesFetchError } = useFetchWithParameters(warehousesApiUrl, parameters);

    useEffect(() => {
        if (warehousesFetchResponse) {
            setWarehousesTotalCount(warehousesFetchResponse?.totalCount);
            setWarehouses(warehousesFetchResponse?.resultList ?? []);
        }
    }, [warehousesFetchResponse])

    const handleWarehousesFilterSubmit = (e) => {
        e.preventDefault();

        setWarehousesParameters(current => ({ ...current, ...warehousesFilters }))
    }


    const sheltersApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getFacSplyLst';

    const [sheltersParameters, setSheltersParameters] = useState({
        splyId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [shelters, setShelters] = useState([])
    const [sheltersTotalCount, setSheltersTotalCount] = useState(null);
    const [sheltersFilters, setSheltersFilters] = useState({})


    const { fetchResponse: sheltersFetchResponse, fetchIsLoading: sheltersFetchIsLoading, fetchError: sheltersFetchError } = useFetchWithParameters(sheltersApiUrl, parameters);

    useEffect(() => {
        if (sheltersFetchResponse) {
            setSheltersTotalCount(sheltersFetchResponse?.totalCount);
            setShelters(sheltersFetchResponse?.resultList ?? []);
        }
    }, [sheltersFetchResponse])

    const handleSheltersFilterSubmit = (e) => {
        e.preventDefault();

        setSheltersParameters(current => ({ ...current, ...sheltersFilters }))
    }

    const distributionsApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getPayoutHist';

    const [distributionsParameters, setDistributionsParameters] = useState({
        splyId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [distributions, setDistributions] = useState([])
    const [distributionsTotalCount, setDistributionsTotalCount] = useState(null);
    const [distributionsFilters, setDistributionsFilters] = useState({})


    const { fetchResponse: distributionsFetchResponse, fetchIsLoading: distributionsFetchIsLoading, fetchError: distributionsFetchError } = useFetchWithParameters(distributionsApiUrl, parameters);

    useEffect(() => {
        if (distributionsFetchResponse) {
            setDistributionsTotalCount(distributionsFetchResponse?.totalCount);
            setDistributions(distributionsFetchResponse?.resultList ?? []);
        }
    }, [distributionsFetchResponse])

    const handleDistributionsFilterSubmit = (e) => {
        e.preventDefault();

        setDistributionsParameters(current => ({ ...current, ...distributionsFilters }))
    }

    const [kind, setKind] = useState({})
    const [cat1, setCat1] = useState({})
    const [cat2, setCat2] = useState({})
    const [cat3, setCat3] = useState({})
    const [cat4, setCat4] = useState({})
    const [memo, setMemo] = useState({})
    const [quantity, setQuantity] = useState({})

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getSply?splyId=' + id;

    useEffect(() => {
        if (!apiUrl) return;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setCat1(resultObject.cat1);
                setCat2(resultObject.cat2);
                setCat3(resultObject.cat3);
                setCat4(resultObject.cat4);
                setKind(resultObject.kind);
                setQuantity(resultObject.quantity);
                setMemo(resultObject.memo);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl]);

    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [facilityType, setFacilityType] = useState(null)

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])
    // const [parameters, setParameters] = useState({
    //     typeCd: '02',
    //     reqPageNo: 1,
    //     reqPageSize: 10,
    // })

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current,
            reqPageNo: 1,
            splyId: id,
            ...filters,
            ...(area1?.value ? { area1Cd: area1.value } : null),
            ...(area2?.value ? { area2Cd: area2.value } : null),
            ...(facilityType?.value ? { facilityType: facilityType.value } : null),
        }))
    }

    const initializeFilter = (splyid) => {
        setFilters({});
        setArea2(null)
        setFacilityType(null)
        setParameters(current => ({ typeCd: '02', reqPageNo: 1, reqPageSize: current?.reqPageSize, splyid: id }))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
        ApiHelper.loadDropdown('groupCode=D003&codeDepth=1', setFacilityTypes)
    }, [])

    useEffect(() => {
        if (areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + area1?.value, setAreas2)
    }, [area1])

    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expFacSplyXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const handleSaveExcel2 = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expPayoutHistXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }



    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Relief supplies</h3>
                <Link to={'/inventory-management'} className="sidebar-link active">Inventory management</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">Warehouse management</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">Incoming supplies</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">Outgoing supplies</Link>
                <Link to={'/requests-for-supplies'} className="sidebar-link">Requests for supplies</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Manage supply relief inventory</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">Modified: 2023-07-23 10:50:00</p>
                            <Link to="/inventory-management" className="dms-btn dms-btn-s dms-btn-outline">To List</Link>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="Supply details">
                            <div className="d-flex justify-content-end align-items-center mb-20px">
                                <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Modify</button>
                            </div>

                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Detail view</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Item type</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={kind}
                                                value={kind}
                                                isSearchable={false}
                                                isDisabled={true}
                                                options={[
                                                    { value: 'General', label: 'General' },
                                                    { value: 'Test', label: 'Test' },
                                                    { value: 'Test Test', label: 'Test Test' }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Categorizing items</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={cat1}
                                                isSearchable={false}
                                                isDisabled={true}
                                                value={cat1}
                                                options={[
                                                    { value: 'Category 1', label: 'Category 1' },
                                                    { value: 'Test', label: 'Test' },
                                                    { value: 'Test Test', label: 'Test Test' }
                                                ]}
                                            />
                                        </div>
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={cat2}
                                                value={cat2}
                                                isSearchable={false}
                                                isDisabled={true}
                                                options={[
                                                    { value: 'Category 2', label: 'Category 2' },
                                                    { value: 'Test', label: 'Test' },
                                                    { value: 'Test Test', label: 'Test Test' }
                                                ]}
                                            />
                                        </div>
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={cat3}
                                                value={cat3}
                                                isSearchable={false}
                                                isDisabled={true}
                                                options={[
                                                    { value: 'Category 3', label: 'Category 3' },
                                                    { value: 'Test', label: 'Test' },
                                                    { value: 'Test Test', label: 'Test Test' }
                                                ]}
                                            />
                                        </div>
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={cat4}
                                                value={cat4}
                                                isSearchable={false}
                                                isDisabled={true}
                                                options={[
                                                    { value: 'Category 4', label: 'Category 4' },
                                                    { value: 'Test', label: 'Test' },
                                                    { value: 'Test Test', label: 'Test Test' }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Warehousing quantity</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-3">
                                            <div className="input-group disabled">
                                                <FormControl placeholder="Enter quantity" value={quantity} disabled />
                                                <span className="input-group-text">EA</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-1">Relief item description</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-9">
                                            <FormControl as="textarea" rows="8"
                                                value={memo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab2" title="Warehouse">
                            <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select a region/shelter</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Select Province'}
                                                    value={area1 ?? null}
                                                    onChange={(e) => setArea1(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Entire city'}
                                                    value={area2 ?? null}
                                                    onChange={(e) => setArea2(e)}
                                                    options={areas2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Facility Type</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'All'}
                                                    value={facilityType ?? null}
                                                    onChange={(e) => setFacilityType(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </form>

                            {warehousesTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {warehousesTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {sheltersFetchError ? <p className="search-result text-orange">{sheltersFetchError}</p> : null}
                                            {sheltersFetchIsLoading ? <p className="search-result">Loading data</p> :
                                                warehousesTotalCount !== null ? <p className="search-result">Search Result <span>{warehousesTotalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{ label: 'View By ' + parameters.reqPageSize }}
                                                onChange={(e) => setParameters(current => ({ ...current, reqPageSize: e.value }))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: 'View By 10' },
                                                    { value: 20, label: 'View By 20' },
                                                    { value: 30, label: 'View By 30' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {warehousesTotalCount !== null && warehousesTotalCount !== 0 ? (

                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>Warehouse Type</th>
                                            <th>Province</th>
                                            <th>District</th>
                                            <th>Warehouse Name</th>
                                            <th>Inventory</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {warehouses.map(item => (

                                            <tr key={item.id}>
                                                <td>{item.classCdName}</td>
                                                <td>{item.area1}</td>
                                                <td>{item.area2}</td>
                                                <td>{item.name}</td>
                                                <td>{item.splyCnt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {warehousesTotalCount !== null && warehousesTotalCount !== 0 && Math.ceil(warehousesTotalCount / warehousesParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(warehousesParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(warehousesTotalCount / warehousesParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab3" title="Shelter">
                            <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select a region/shelter</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Select Province'}
                                                    value={area1 ?? null}
                                                    onChange={(e) => setArea1(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Entire city'}
                                                    value={area2 ?? null}
                                                    onChange={(e) => setArea2(e)}
                                                    options={areas2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Facility Type</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'All'}
                                                    value={facilityType ?? null}
                                                    onChange={(e) => setFacilityType(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </form>

                            {sheltersTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {sheltersTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { sheltersFetchError ? <p className="search-result text-orange">{sheltersFetchError}</p> : null }
                                            { sheltersFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                sheltersTotalCount !== null ? <p className="search-result">Search Result <span>{sheltersTotalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{ label: 'View By ' + parameters.reqPageSize }}
                                                onChange={(e) => setParameters(current => ({ ...current, reqPageSize: e.value }))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: 'View By 10' },
                                                    { value: 20, label: 'View By 20' },
                                                    { value: 30, label: 'View By 30' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            
                            {sheltersTotalCount !== null && sheltersTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>Province</th>
                                            <th>District</th>
                                            <th>Address</th>
                                            <th>Shelter Name</th>
                                            <th>Inventory</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shelters.map(item => (

                                            <tr key={item.id}>
                                                <td>{item.classCdName}</td>
                                                <td>{item.area1}</td>
                                                <td>{item.area2}</td>
                                                <td>{item.name}</td>
                                                <td>{item.splyCnt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {sheltersTotalCount !== null && sheltersTotalCount !== 0 && Math.ceil(sheltersTotalCount / sheltersParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setSheltersParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(sheltersParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(sheltersTotalCount / sheltersParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab4" title="Distribution status">
                            <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select a region/shelter</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Select Province'}
                                                    value={area1 ?? null}
                                                    onChange={(e) => setArea1(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Entire city'}
                                                    value={area2 ?? null}
                                                    onChange={(e) => setArea2(e)}
                                                    options={areas2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Facility Type</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'All'}
                                                    value={facilityType ?? null}
                                                    onChange={(e) => setFacilityType(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </form>

                            {distributionsTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {distributionsTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { distributionsFetchError ? <p className="search-result text-orange">{distributionsFetchError}</p> : null }
                                            { distributionsFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                distributionsTotalCount !== null ? <p className="search-result">Search Result <span>{distributionsTotalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{ label: 'View By ' + parameters.reqPageSize }}
                                                onChange={(e) => setParameters(current => ({ ...current, reqPageSize: e.value }))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: 'View By 10' },
                                                    { value: 20, label: 'View By 20' },
                                                    { value: 30, label: 'View By 30' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcel2(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {distributionsTotalCount !== null && distributionsTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>Processing date</th>
                                            <th>Disaster name</th>
                                            <th>Province</th>
                                            <th>District</th>
                                            <th>Shelter</th>
                                            <th>Person responsible<br />for unstoring</th>
                                            <th>Recipient</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {distributions.map(item => (

                                            <tr key={item.id}>
                                                <td>{item.splyDistDt}</td>
                                                <td>{item.disasterName}</td>
                                                <td>{item.area1}</td>
                                                <td>{item.area2}</td>
                                                <td>{item.facName}</td>
                                                <td>{item.chargeName}</td>
                                                <td>{item.recvName}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                            
                            {distributionsTotalCount !== null && distributionsTotalCount !== 0 && Math.ceil(distributionsTotalCount / distributionsParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(distributionsParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(distributionsTotalCount / distributionsParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                    </Tabs>
                </div>

                <Footer />
            </div>
            <SaveExcelModal handleSaveExcel={handleSaveExcel} isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
            <SaveExcelModal handleSaveExcel={handleSaveExcel2} isVisible={isVisibleSaveExcel2} setIsVisible={setIsVisibleSaveExcel2} />

        </div>
    );
}

export default InventoryDetails;
import React, { useState, useEffect, useRef } from 'react';
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import { Editor } from '@tinymce/tinymce-react';

const NoticesNewsDetails = () => {
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const editorRef = useRef(null);
    const { id } = useParams();



    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [memo, setMemo] = useState('');
    const [subject, setSubject] = useState('');

    // Default values for facilityId and writerId
    const [facilityId] = useState(14);
    const [writerId] = useState('admin_001');

    const [fetchResponse, setFetchResponse] = React.useState(null);
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/noti/getNotice?noticeId=' + id;

    useEffect(() => {
        if (!apiUrl) return;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setSubject(resultObject.subject);
                setMemo(resultObject.memo);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl]);

    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }




    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            memo,
            subject,
            facilityId,
            writerId,
            noticeId: id
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/noti/updateNotice', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            });
    };

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">System management</h3>
                <Link to={'/statistics'} className="sidebar-link">Statistics</Link>
                <Link to={'/user-management'} className="sidebar-link">User management</Link>
                <Link to={'/disaster-management'} className="sidebar-link">Disaster management</Link>
                <Link to={'/notices-news'} className="sidebar-link active">Notices/News</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container1">
                    <div className="inner-container">
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">Write a Notice/News</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <p className="last-modified text-orange">Modified: 2023-07-23 10:50:00</p>
                                <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                                <button type="submit" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Modify</button>
                            </div>
                        </div>

                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="white-rounded-container mb-20px">
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Title<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl
                                            placeholder="Please enter a name"
                                            value={subject}  
                                            onChange={e => setSubject(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <Editor
                                    apiKey='ds9a45q8iocswd1yxliywcofzfu6bh1ywbla5pway156xhtn'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={memo || ''} // Set the initial value
                                    // /initialValue="<p>This is the initial content of the editor.</p>"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onEditorChange={content => setMemo(content)}

                                />
                            </div>
                        </div>

                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>File attachments</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label mt-2">Uploading files</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl placeholder="Please enter a name" />
                                        <div className="input-helper">Upload up to 4 files.</div>
                                    </div>
                                    <div className="dms-input-4">
                                        <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />Find File</button>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-100">
                                        <table className="table dms-table-1">
                                            <thead>
                                                <tr>
                                                    <th>Filename</th>
                                                    <th>Preview</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-start">
                                                        012371923712.jpg
                                                        <button className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex ms-2"><IoClose size="16px" /> Delete</button>
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        <Link className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">Preview</Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <Footer />
            </div>
        </div>
    );
}

export default NoticesNewsDetails;
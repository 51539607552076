import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link, useParams } from "react-router-dom";
import { Alert, FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import ApiHelper from "../Helpers/ApiHelper";
import useFetch from "../useFetch";
import { useSelector } from "react-redux";

const VictimShelterBelongEditInquiry = () => {
    const { id } = useParams()
    const { user } = useSelector(state => state.user);
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/fac/getInq?boardId='+id)
    
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    
    const [type, setType] = useState(null)
    const [description, setDescription] = useState('')
    const [subject, setSubject] = useState('')
    const [answer, setAnswer] = useState('')
    const [status, setStatus] = useState('')
    const [facilityName, setFacilityName] = useState('')
    const [writerName, setWriterName] = useState('')
    const [updateDt, setUpdateDt] = useState('')

    const [types, setTypes] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D007&codeDepth=1', setTypes)
    }, [])

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setType(fetchResponse?.resultObject?.typeCd)
            setDescription(fetchResponse?.resultObject?.questionMemo)
            setSubject(fetchResponse?.resultObject?.subject)
            setAnswer(fetchResponse?.resultObject?.answerMemo)
            setStatus(fetchResponse?.resultObject?.statusCd)
            setFacilityName(fetchResponse?.resultObject?.facilityName)
            setWriterName(fetchResponse?.resultObject?.writerName)
            setUpdateDt(fetchResponse?.resultObject?.updateDt ?? fetchResponse?.resultObject?.createDt)
        }
    }, [fetchResponse])

    const handleSubmit = (e) => {
        if (isLoadingSubmit) return

        e.preventDefault()

        setError(null)
        setSuccess(null)
        setIsLoadingSubmit(true)
        
        var formData = {
            boardId: id,
            questionMemo: description,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/updateInq', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Edit Inquiry</div>
                    <Link to={"/shelter-victim-inquiry-details-belong-m/"+id} className="mobile-navbar-right plain-link">
                        <img src="/images/icons/close.svg" />
                    </Link>
                </div>
            </div>
            
            <div className="container flex-fill pt-3 pb-2">
                {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                <div className="form-group">
                    <div className="dms-form-label align-self-center">Inquiry Type</div>
                    <ReactSelect
                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                        classNamePrefix="select"
                        className="dropdown-sm mr"
                        placeholder={"Select Inquiry Type"}
                        value={types ? types.find(value => value.value == type) ?? null : null}
                        onChange={(e) => setType(e.value)}
                        isSearchable={false}
                        isDisabled={true}
                        options={types}
                    />
                </div>
                <div className="form-group">
                    <div className="dms-form-label align-self-center">Shelter</div>
                    <FormControl placeholder={facilityName} disabled />
                </div>
                <div className="form-group">
                    <div className="dms-form-label align-self-center">Requested By</div>
                    <FormControl placeholder={writerName} disabled />
                </div>
                <div className="form-group">
                    <div className="dms-form-label align-self-center">Details description</div>
                    <FormControl 
                        as="textarea"
                        required={true}
                        rows="5"
                        onChange={e => setDescription(e.target.value.substring(0,240))}
                        value={description} />
                    <div className="input-helper text-end">{description.length}/240</div>
                </div>
            </div>
            
            <div className="sticky-bottom mobile-bottom-btn-container">
                <p className="fs-xxs bg-cool-gray text-gray-04 px-3 py-2 mb-0">If the question is registered, it will not be possible to edit it.</p>
                <button type="button" onClick={handleSubmit} className="dms-btn dms-btn-l dms-btn-filled">Register</button>
            </div>
        </div>
    );
}
 
export default VictimShelterBelongEditInquiry;
import { useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";

const AdminShelterBelongInquiriesDetails1 = () => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="mobile-navbar-left">
                        <img src="/images/icons/back.svg" />
                    </div>
                    <div className="mobile-navbar-title">Shelter inquiry board</div>
                    <div className="blank-icon" />
                </div>
            </div>
            
            <div className="content-container container flex-fill pb-4">
                <div className="page-heading-container mb-20px mt-0">
                    <h3 className="page-heading mb-0">Requesting relief supplies</h3>
                    <p className="fs-xs mb-2">- Test test</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="last-modified">2023-07-23 12:12:00</p>
                        <div className="table-actions-container d-flex justify-content-between">
                            <Link to="/shelter-victim-edit-inquiry-belong-m" className="dms-btn dms-btn-s dms-btn-outline mr">Modify</Link>
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline">Remove</button>
                        </div>
                    </div>
                </div>
                <div className="mobile-card">
                    <p className="d-inline-block bg-cool-gray text-gray-04 px-3 py-1 mb-2">Inquiry</p>
                    <p className="mb-1">A long text long text long text A long text long text long text A long text long text long text.</p>
                    <p className="mb-1">A long text long text long text A long text long text long text.</p>
                    <p className="mb-1">A long text long text long text.</p>
                </div>
                <Link to={'/shelter-admin-inquiry-answer-belong-m'} className="dms-btn dms-btn-m dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center mt-3"><GoPlusCircle size="22px" />Answer</Link>
            </div>
            
            <div className="container content-container pt-0">
                <Link to="/shelter-admin-inquiries-belong-m" className="dms-btn dms-btn-to-list-mobile dms-btn-outline w-100 px-0 align-self-center">To List</Link>
            </div>
        </div>
    );
}
 
export default AdminShelterBelongInquiriesDetails1;
import { useRef, useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoClose } from "react-icons/io5";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const RequestsForSuppliesDetails = () => {
    const uploadFileRef = useRef()

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [image, setImage] = useState(false)

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Relief supplies</h3>
                <Link to={'/inventory-management'} className="sidebar-link">Inventory management</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">Warehouse management</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">Incoming supplies</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">Outgoing supplies</Link>
                <Link to={'/requests-for-supplies'} className="sidebar-link active">Requests for supplies</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Requests for supplies</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">Modified: 2023-07-23 10:50:00</p>
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">Delete</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">Modify</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Confirm</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Details</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Requested By<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Requested Relief Station<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Ulsan'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Ulsan', label: 'Ulsan'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Citywide'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Citywide', label: 'Citywide'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Select the shelter or warehouse'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Select the shelter or warehouse', label: 'Select the shelter or warehouse'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Phone number</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Categorizing items<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category', label: 'Category'},
                                            { value: 'Female', label: 'Female'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Item category<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 1'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 1', label: 'Category 1' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 2'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 2', label: 'Category 2' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 3'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 3', label: 'Category 3' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 4'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 4', label: 'Category 4' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-1">Request details</div>
                            <div className="dms-input-row">
                                <div className="dms-input-12">
                                    <FormControl as="textarea" rows="8" />  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Download file</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-2">Photo upload</div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Please enter a name" />
                                    <div className="input-helper">Upload up to 4 images. Uploadable extensions: JPG, JPEG, PNG</div>
                                </div>
                                <div className="dms-input-4">
                                    <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />Find File</button>
                                </div>
                            </div>
                        </div>

                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-100">
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>Filename</th>
                                                <th>Preview</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-start">
                                                    012371923712.jpg
                                                    <button className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex ms-2"><IoClose  size="16px" /> Delete</button>
                                                </td>
                                                <td style={{ width: "100px" }}>
                                                    <Link className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">Preview</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default RequestsForSuppliesDetails;
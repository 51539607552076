import { useState, useEffect } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";

const ShelterInquiryBoard = () => {
    const [items, setItems] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }])
    const [selectedItems, setSelectedItems] = useState([])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const { id } = useParams('');

    const [totalCount, setTotalCount] = useState(0);
    const [inquiries, setInquiries] = useState([]);
    const inquiriesApiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getInqLst?facilityId=' + id;
    useEffect(() => {
        // Fetch inquiries
        fetch(inquiriesApiUrl)
            .then(response => response.json())
            .then(data => {
                setInquiries(data.resultList);
                setTotalCount(data.totalCount);

            })
            .catch(error => {
                // Handle error if needed
                console.error('Error fetching inquiries:', error);
            });
    }, [inquiriesApiUrl]);



    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Shelter</h3>
                <Link to={'/shelter-management'} className="sidebar-link">Shelter management</Link>
                <Link to={'/shelter-inquiry-board'} className="sidebar-link active">Shelter inquiry board</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Shelter inquiry board</h3>
                    </div>
                    <div className="py-5 text-center text-gray-03 white-rounded-container mb-20px">There is no shelter that the victim entered.</div>
                    <div className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Inquiry Type</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{ label: 'All' }}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: 'All' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Status</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{ label: 'All' }}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: 'All' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Requested by</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-4">
                                        <FormControl placeholder="Please enter a name" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                            <button className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                        </div>
                    </div>
                    <div className="table-actions-container mb-20px">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="search-result">Search Result <span>{totalCount}</span> Items</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <ReactSelect
                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                    classNamePrefix="select"
                                    className="dropdown-sm mr"
                                    value={{ label: 'View By 10' }}
                                    isSearchable={false}
                                    options={[
                                        { value: 'View By 10', label: 'View By 10' },
                                        { value: 'View By 20', label: 'View By 20' },
                                        { value: 'View By 30', label: 'View By 30' },
                                    ]}
                                />
                                <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                            </div>
                        </div>
                    </div>
                    <table className="table dms-table-seperate">
                        <thead>
                            <tr>
                                <th>Inquiry Type</th>
                                <th>Request Description</th>
                                <th>Requested By</th>
                                <th>Representative</th>
                                <th>Disaster</th>
                                <th>Last Written Time</th>
                                <th>Status</th>
                                <th>View Detail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquiries?.map(notice => (
                                <tr key={notice.id}>
                                    <td>{notice.typeCd}</td>
                                    <td>{notice.questionMemo}</td>
                                    <td>{notice.writerName}</td>
                                    <td>{notice.answererName}</td>
                                    <td>{notice.subject}</td>
                                    <td>{notice.updateDt}</td>
                                    <td>{notice.status}</td>
                                    <td>Request this. Right now</td>
                                    <td>Test</td>
                                    <td>Test</td>
                                    <td>Test Test Test Test</td>
                                    <td>2023-07-23 11:21:23</td>
                                    <td style={{ width: "120px" }}><Link to={notice.id % 2 == 0 ? "/shelter-inquiry-details-2" : "/shelter-inquiry-details-1"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                    {/* <td className={notice.facilityId % 2 == 0 ? 'text-green' : ''}>{notice.facilityId % 2 == 0 ? 'Answered' : 'Wait for an answer'}</td> */}
                                    {/* <td style={{ width: "120px" }}><Link to={notice.id % 2 == 0 ? "/shelter-inquiry-details-2" : "/shelter-inquiry-details-1"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ReactPaginate
                        //onPageChange={handlePageClick}
                        forcePage={0}
                        pageCount={5}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        previousLabel={<LuChevronLeft size="20px" />}
                        nextLabel={<LuChevronRight size="20px" />}
                        breakLabel="..."
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        previousClassName="page-item page-previous"
                        nextClassName="page-item page-next"
                        breakClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                    />
                </div>

                <Footer />
            </div>

            <SaveExcelModal isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}

export default ShelterInquiryBoard;
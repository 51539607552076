import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import PreviewQrMobile from "../Components/Modals/PreviewQrMobile";
import { useParams } from "react-router-dom";
import useFetch from "../useFetch";
import { useSelector } from "react-redux";
import MenuMobile from "../MenuMobile";

const QrVictimMobile1 = () => {
    const { user } = useSelector(state => state.user);

    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMInfo?userId='+user.userId)

    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [qrImage, setQrImage] = useState(null)
    const [isVisibleQr, setIsVisibleQr] = useState(false)
    const [selectedInfant, setSelectedInfant] = useState(null)

    const [name, setName] = useState('')
    const [infants, setInfants] = useState([])

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setName(fetchResponse?.resultObject?.name)
            setInfants(fetchResponse?.resultObject?.infantsList?.map(value => ({
                id: value.infantId, 
                name: value.name, 
                regNo: value.regNo,
            })))
        }
    }, [fetchResponse])

    useEffect(() => {
        setQrImage(null)

        const url = selectedInfant === null ? '/victim/getVCTMQRCode?requestUserId=' + user.userId : '/victim/getINFQRCode?parentUserId='+user.userId+'&infantUserId='+selectedInfant.id
        fetch(process.env.REACT_APP_BASE_API_URL + url)
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            setQrImage(window.URL.createObjectURL(new Blob([data])))
        })
        .catch(error => {
        })
    }, [selectedInfant])

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="bg-blue text-white container py-4">
                    <p className="fs-xxs mb-1">2023-08-12 12:12:00 outbreak</p>
                    <h4 className="mb-2">Typhoon warning for all of Ulsan</h4>
                    <p className="fs-xxs mb-0">Typhoon 6 kanun, northward from 10 days</p>
                </div>
            </div>
            
            <div className="content-container container flex-fill pt-0 pb-2">
                {selectedInfant === null ? (
                    <div className="qr-box-mobile">
                        <div className="qr-code-mobile">
                            <img onClick={() => setIsVisibleQr({image: qrImage, name: name})} src={qrImage ?? '/images/loading.svg'} className="img-fluid" />
                        </div>
                        <div className="qr-code-footer-mobile">
                            <h4>QR Code</h4>
                            <p>{name}</p>
                        </div>
                    </div>
                ) : (
                    <div className="mobile-card mt-3 shadow-sm">
                        <div className="d-flex align-items-center">
                            <p className="flex-fill mb-0">{name}</p>
                            <button onClick={() => { if(qrImage) setSelectedInfant(null)}} type="button" className="dms-btn dms-btn-xs dms-btn-outline me-2">Select</button>
                        </div>
                    </div>
                )}
                
                {infants.map(infant => (
                    selectedInfant?.id === infant?.id ? (
                        <div className="qr-box-mobile">
                            <div className="qr-code-mobile">
                                <img onClick={() => setIsVisibleQr({image: qrImage, name: infant.name})} src={qrImage ?? '/images/loading.svg'} className="img-fluid" />
                            </div>
                            <div className="qr-code-footer-mobile">
                                <h4>QR Code</h4>
                                <p>{infant.name}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="mobile-card mt-3 shadow-sm">
                            <div className="d-flex align-items-center">
                                <p className="flex-fill mb-0">{infant.name}</p>
                                <button onClick={() => { if(qrImage) setSelectedInfant(infant)}} type="button" className="dms-btn dms-btn-xs dms-btn-outline me-2">Select</button>
                            </div>
                        </div>
                    )
                ))}

                <p className="text-center mt-3 text-gray-04">Click the QR Code to enlarge it.</p>
            </div>

            <FooterMobile user={user} active="QR Code" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />

            <PreviewQrMobile data={isVisibleQr} isVisible={isVisibleQr !== false} setIsVisible={setIsVisibleQr} />
        </div>
    );
}
 
export default QrVictimMobile1;
import React, { useState, useEffect, useRef } from 'react';
import { FormCheck, FormControl, Tab, Tabs, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import useFetchWithParameters from "../useFetchWithParameters";
import { setUser } from "../redux/userSlice";
import { useSelector } from "react-redux";


const TransferSupplies = () => {
    const { user } = useSelector(state => state.user);
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);

    const [sentFrom, setSentFrom] = useState('')
    const [sentTo, setSentTo] = useState('')
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [shelter, setShelter] = useState(null)


    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getIncomSplyLst';
    const [itemTypes, setItemTypes] = useState([])
    const [categories1, setCategories1] = useState([])
    const [categories2, setCategories2] = useState([])
    const [categories3, setCategories3] = useState([])
    const [categories4, setCategories4] = useState([])
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [shelters, setShelters] = useState([])

    const [requesterUserId, setRequesterUserId] = useState('');
    const [splyId, setSplyId] = useState('1');
    const [quantity, setQuantity] = useState('');
    const [fromType, setFromType] = useState('');
    const [toType, setToType] = useState('');
    const [fromName, setFromName] = useState('');
    const [fromAddr, setFromAddr] = useState('');
    const [fromAdrDtl, setFromAdrDtl] = useState('');
    const [fromZipCd, setFromZipCd] = useState('');
    const [fromFacilityId, setFromFacilityId] = useState('');
    const [toName, setToName] = useState('');
    const [toAddr, setToAddr] = useState('');
    const [toAdrDtl, setToAdrDtl] = useState('');
    const [toZipCd, setToZipCd] = useState('');
    const [toFacilityId, setToFacilityId] = useState('');
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')

    const [isVisibleAddress2, setIsVisibleAddress2] = useState(false)
    const [zipCode2, setZipCode2] = useState('')
    const [mainAddress2, setMainAddress2] = useState('')
    const [detailedAddress2, setDetailedAddress2] = useState('')

    const [inOutArea1, setInOutArea1] = useState(null)
    const [inOutArea2, setInOutArea2] = useState(null)
    const [inOutShelter, setInOutShelter] = useState(null)

    const [inOutAreas1, setInOutAreas1] = useState([])
    const [inOutAreas2, setInOutAreas2] = useState([])
    const [inOutShelters, setInOutShelters] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setInOutAreas1)
    }, [])

    useEffect(() => {
        if (inOutAreas1.length > 0) setInOutArea1(inOutAreas1[0])
    }, [inOutAreas1])


    useEffect(() => {
        if (!inOutArea1) return
        setInOutArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + inOutArea1?.value, setInOutAreas2)
    }, [inOutArea1])

    useEffect(() => {
        if (!inOutArea2) return
        setInOutShelter(null)

        ApiHelper.loadDropdown('area2Cd=' + inOutArea2?.value + '&typeCd=02', setInOutShelters, 'fac/getFACLst', 'facilityId')
    }, [inOutArea2])


    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            requesterUserId: userId,
            splyId,
            quantity,
            fromType: sentFrom,
            toType: sentTo,
            fromName,
            fromAddr: mainAddress,
            fromAdrDtl: detailedAddress,
            fromZipCd: zipCode,
            fromFacilityId,
            toName,
            toAddr: mainAddress,
            toAdrDtl: detailedAddress,
            toZipCd: zipCode,
            toFacilityId,

        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/sply/movSply', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            });
    };



    const [parameters, setParameters] = useState({
        status: 'IN',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    // const handleFilterSubmit = (e) => {
    //     e.preventDefault();

    //     setParameters(current => ({
    //         ...current,
    //         reqPageNo: 1,
    //         ...filters,
    //         ...(area1?.value ? { area1Cd: area1.value } : null),
    //         ...(area2?.value ? { area2Cd: area2.value } : null),
    //         ...(shelter?.value ? { facilityId: shelter.value } : null),
    //         ...(filters?.type ? { kindCd: filters?.type } : null),
    //         ...(filters?.cat1 ? { cat1Cd: filters?.cat1.substring(filters?.type.length) } : null),
    //         ...(filters?.cat2 ? { cat2Cd: filters?.cat2.substring(filters?.cat1.length) } : null),
    //         ...(filters?.cat3 ? { cat3Cd: filters?.cat3.substring(filters?.cat2.length) } : null),
    //         ...(filters?.cat4 ? { cat4Cd: filters?.cat4.substring(filters?.cat3.length) } : null),
    //     }))
    // }

    const initializeFilter = () => {
        setFilters({});
        setArea2(null)
        setShelter(null)
        setParameters(current => ({ status: 'IN', reqPageNo: 1, reqPageSize: current?.reqPageSize }))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if (areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!area2) return
        setShelter(null)

        ApiHelper.loadDropdown('area2Cd=' + area2?.value + '&typeCd=02', setShelters, 'fac/getFACLst', 'facilityId')
    }, [area2])

    useEffect(() => {
        if (!filters?.type) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setCategories2([])
        setCategories3([])
        setCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1=' + filters?.type, setCategories1)
    }, [filters?.type])

    useEffect(() => {
        if (!filters?.cat1) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setCategories3([])
        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1=' + filters?.type + '&codeLev2=' + cat1, setCategories2)
    }, [filters?.cat1])

    useEffect(() => {
        if (!filters?.cat2) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });

        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1=' + filters?.type + '&codeLev2=' + cat1 + '&codeLev3=' + cat2, setCategories3)
    }, [filters?.cat2])

    useEffect(() => {
        if (!filters?.cat3) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)
        var cat3 = filters?.cat3.substring(filters?.cat2.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1=' + filters?.type + '&codeLev2=' + cat1 + '&codeLev3=' + cat2 + '&codeLev4=' + cat3, setCategories4)
    }, [filters?.cat3])

    const apiUrl2 = `${process.env.REACT_APP_BASE_API_URL}/auth/getUser?userId=${user.userId}`;

    useEffect(() => {
        if (!apiUrl2) return;

        fetch(apiUrl2)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setUserId(resultObject.userId);
                setName(resultObject.name);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl2]);

    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Relief supplies</h3>
                <Link to={'/inventory-management'} className="sidebar-link active">Inventory management</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">Warehouse management</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">Incoming supplies</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">Outgoing supplies</Link>
                <Link to={'/requests-for-supplies'} className="sidebar-link">Requests for supplies</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}
                <form onSubmit={handleSubmit}>
                    <div className="inner-container">
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">Transfer supplies</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                                <button type="submit" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Register</button>
                            </div>
                        </div>

                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>Select item</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Item type<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.type ? itemTypes.find(value => value.value == filters?.type) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, type: e.value }))}
                                            isSearchable={false}
                                            options={itemTypes}
                                        />

                                        {/* <ReactSelect
                                        components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                        classNamePrefix="select"
                                        placeholder={'Category'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category', label: 'Category' },
                                            { value: 'Female', label: 'Female' },
                                            { value: 'External', label: 'External' },
                                            { value: 'Facility', label: 'Facility' },
                                        ]}
                                    /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Categorizing items<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat1 ? categories1.find(value => value.value == filters?.cat1) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat1: e.value }))}
                                            isSearchable={false}
                                            options={categories1}
                                        />
                                    </div>
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat2 ? categories2.find(value => value.value == filters?.cat2) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat2: e.value }))}
                                            isSearchable={false}
                                            options={categories2}
                                        />
                                    </div>
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat3 ? categories3.find(value => value.value == filters?.cat3) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat3: e.value }))}
                                            isSearchable={false}
                                            options={categories3}
                                        />
                                    </div>
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat4 ? categories4.find(value => value.value == filters?.cat4) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat4: e.value }))}
                                            // onChange={e => setSplyId(e.target.value)}
                                            isSearchable={false}
                                            options={categories4}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Warehousing quantity<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <div className="input-group">
                                            <FormControl
                                                value={quantity}
                                                onChange={e => setQuantity(e.target.value)}
                                                placeholder="Enter quantity" />
                                            <span className="input-group-text">EA</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>Sent from</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Type<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Type'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'E', label: 'External' },
                                                { value: 'F', label: 'Facility' },
                                            ]}
                                            onChange={(e) => setSentFrom(e.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {sentFrom == 'E' ? (
                                <>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Sent from<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <FormControl
                                                    value={fromName}
                                                    onChange={e => setFromName(e.target.value)}
                                                    placeholder="Enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">From address</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    value={zipCode}
                                                    placeholder="Please choose address"
                                                    disabled={true} />
                                            </div>

                                            <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    value={mainAddress}
                                                    placeholder="Please choose address"
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    onChange={e => setDetailedAddress(e.target.value)}
                                                    value={detailedAddress}
                                                    placeholder="Enter detailed address"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {sentFrom == 'F' ? (
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Where to send<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-2">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={'Ulsan'}
                                                isSearchable={false}
                                                onChange={(e) => setToAddr(e.value)}
                                                options={inOutAreas1}
                                            />
                                        </div>
                                        <div className="dms-input-2">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={'Citywide'}
                                                isSearchable={false}
                                                value={inOutArea2 ?? null}
                                                onChange={(e) => setInOutArea2(e)}
                                                options={inOutAreas2}
                                            />
                                        </div>
                                        <div className="dms-input-6">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={'Select the shelter or warehouse'}
                                                isSearchable={false}

                                                onChange={(e) => setFromFacilityId(e.value)}
                                                options={inOutShelters}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>Sent to</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Type<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Type'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'E', label: 'External' },
                                                { value: 'F', label: 'Facility' },
                                            ]}
                                            onChange={(e) => setSentTo(e.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {sentTo == 'E' ? (
                                <>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Sent to<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <FormControl
                                                    value={toName}
                                                    onChange={e => setToName(e.target.value)}
                                                    placeholder="Enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">To address</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    value={zipCode}
                                                    placeholder="Please choose address"
                                                    disabled={true} />
                                            </div>

                                            <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    value={mainAddress}
                                                    placeholder="Please choose address"
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    onChange={e => setDetailedAddress(e.target.value)}
                                                    value={detailedAddress}
                                                    placeholder="Enter detailed address"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {sentTo == 'F' ? (
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Where to send<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-2">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={'Ulsan'}
                                                isSearchable={false}
                                                onChange={(e) => setToAddr(e.value)}
                                                options={inOutAreas1}
                                            />
                                        </div>
                                        <div className="dms-input-2">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={'Citywide'}
                                                isSearchable={false}
                                                value={inOutArea2 ?? null}
                                                onChange={(e) => setInOutArea2(e)}
                                                options={inOutAreas2}
                                            />
                                        </div>
                                        <div className="dms-input-6">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                placeholder={'Select the shelter or warehouse'}
                                                isSearchable={false}

                                                onChange={(e) => setToFacilityId(e.value)}
                                                options={inOutShelters}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                </form>
            </div >
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress}
                setIsVisible={setIsVisibleAddress}
                fullscreen={true} />

        </div >
    );
}

export default TransferSupplies;
import { useEffect, useState } from "react";
import { Carousel, FormControl } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoCaretUp } from "react-icons/io5";
import { FaAngry } from "react-icons/fa";
import { IoChevronDown, IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useSelector } from "react-redux";
import { store } from "./redux/store";
import { setUser } from "./redux/userSlice";

const Login = () => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (user.token) navigate('/')
    }, [user])

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    
    const [email, setEmail] = useState('admin_006');
    const [password, setPassword] = useState('aaAA11!!');

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showGuideMobile, setShowGuideMobile] = useState(false)
    const [showGuide, setShowGuide] = useState(true)
    const carouselItems = 4;
    
    const handlePrevious = () => {
        setCarouselIndex(current => current == 0 ? 0 : current - 1)
    }
    
    const handleNext = () => {
        setCarouselIndex(current => current == carouselItems ? carouselItems : current + 1)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setError(false);

        var parameters = new URLSearchParams({
            userId: email,
            password: password,
        })
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/login?'+parameters, {
            method: 'POST',
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }
            
            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                const detailsUrl = data.typeCd == '02' ? 'victim/getVCTMInfo?userId='+data.resultObject.userId : 'auth/getUser?userId='+data.resultObject.userId
                fetch(process.env.REACT_APP_BASE_API_URL+'/'+detailsUrl)
                .then(response => {
                    if (!response.ok) throw Error('Failed to load data');
                    return response.json();
                })
                .then(dataDetails => {
                    setIsLoading(false);
                    
                    store.dispatch(setUser({
                        typeCd: data.resultObject.typeCd,
                        type: data.resultObject.typeCd == '01' ? 'admin' : (data.resultObject.typeCd == '02' ? 'victim' : 'volunteer'),
                        token: data.resultObject.token,
                        facilityId: dataDetails.resultObject?.shelterId,
                        userId: data.resultObject.userId,
                        userName: data.resultObject.userName,
                        resultObject: dataDetails.resultObject,
                    }));
    
                    navigate('/')
                })
                .catch(error => {
                    setError(data.responseMessage ?? 'There was some error while logging in, please try again.');
                    setIsLoading(false);
                })
            }
            else
            {
                setIsLoading(false);
                setError(data.responseMessage ?? 'There was some error while logging in, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    if (user.token) return null

    return (
        <div className="content-container login-page d-flex p-0 flex-fill d-sm-block">
            {showGuideMobile ? (
                <div style={{ backgroundColor: '#F2F2F2' }}>
                    <Carousel interval={3000} controls={false} className="login-full-carousel" wrap={false}>
                        <Carousel.Item>
                            <img src="/images/slides/page001_mo.png" className="d-block object-fit-contain vw-100 vh-100" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src="/images/slides/page002_mo.png" className="d-block object-fit-contain vw-100 vh-100" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src="/images/slides/page003_mo.png" className="d-block object-fit-contain vw-100 vh-100" />
                            <Carousel.Caption>
                                <button type="button" onClick={() => setShowGuideMobile(false)} className="dms-btn dms-btn-s dms-btn-filled px-5">Confirmed</button>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
            ) : (
                <div className="container d-sm-none d-flex flex-column justify-content-between">
                    <button type="button" onClick={() => setShowGuideMobile(true)} className="dms-btn dms-btn-s dms-btn-outline px-3 align-self-center" style={{ position: 'fixed', right: '20px', top: '20px'}}>?</button>
                    <div></div>
                    <form onSubmit={handleSubmit}>
                        <div className="text-center logo-container">
                            <img src="/images/logo-only.png" className="img-fluid logo" />
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">ID</div>
                            <div className="dms-input-row">
                                <div className="dms-input-12">
                                    <FormControl
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        className={error ? 'is-invalid' : ''} 
                                        placeholder="Please enter value" />
                                    {error ? <div className="input-helper-error">{error}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Password</div>
                            <div className="dms-input-row">
                                <div className="dms-input-12">
                                    <FormControl
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        type="password" 
                                        placeholder="Please enter value" />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className={`dms-btn dms-btn-s dms-btn-filled w-100 mb-20px ${isLoading ? 'disabled' : ''}`}>ID Login</button>
                        <div className="forgot-container">
                            <p className="forgot-1">Haven't registered yet?</p>
                            <a href="#" className="forgot-2">Register</a>
                        </div>
                    </form>
                    <div>
                        <p className="forgot-1 text-center pb-3">Contact us for login errors: cs@helpdesk.com</p>
                    </div>
                </div>
            )}
            <div className="d-none d-sm-block">
                <div className="row g-0">
                    <div className="col-md-4 col-lg-3 d-flex">
                        <form onSubmit={handleSubmit} className="white-rounded-container rounded-0 flex-fill d-flex flex-column">
                            <div className="text-center logo-container">
                                <img src="/images/logo-only.png" className="img-fluid logo" />
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">ID</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-12">
                                        <FormControl
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                            className={error ? 'is-invalid' : ''} 
                                            placeholder="Please enter value" />
                                        {error ? <div className="input-helper-error">{error}</div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Password</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-12">
                                        <FormControl
                                            onChange={e => setPassword(e.target.value)}
                                            value={password}
                                            type="password" 
                                            placeholder="Please enter value" />
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-filled w-100 mb-20px ${isLoading ? 'disabled' : ''}`}>ID Login</button>
                            <p className="forgot-1 text-center mt-auto">Contact us for login errors: cs@helpdesk.com</p>
                        </form>
                    </div>
                    {showGuide ? (
                        <div style={{ backgroundColor: '#F2F2F2' }} className="col-md-8 col-lg-9">
                            <Carousel interval={3000} className="login-full-carousel" wrap={false}>
                                <Carousel.Item>
                                    <img src="/images/slides/page001_pc.png" className="d-block object-fit-contain w-100 vh-100" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src="/images/slides/page002_pc.png" className="d-block object-fit-contain w-100 vh-100" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src="/images/slides/page003_pc.png" className="d-block object-fit-contain w-100 vh-100" />
                                    <Carousel.Caption>
                                        <button type="button" onClick={() => setShowGuide(false)} className="dms-btn dms-btn-s dms-btn-filled px-5">Confirmed</button>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    ) : (
                        <div className="col-md-8 col-lg-9 d-flex flex-column justify-content-center min-vh-100">
                            <div className="d-flex flex-column justify-content-center vh-100 px-5">
                                <div className="page-heading-container mb-20px">
                                    <h3 className="page-heading">Ulsan Metropolitan City<br/>Status of evacuee center</h3>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-20px">
                                    <p className="m-0">All <span className="login-center-caret"><IoChevronDown /></span></p>
                                    <div className="login-pagination">
                                        <div onClick={handlePrevious} className="page-btn"><IoChevronBack /></div>
                                        <p>1/99</p>
                                        <div onClick={handleNext} className="page-btn"><IoChevronForward /></div>
                                    </div>
                                </div>
                                <Carousel activeIndex={carouselIndex}>
                                    {['A', 'B', 'C', 'D', 'E', 'F'].map(value1 => (
                                        <Carousel.Item>
                                            <div className="row">
                                                {['A', 'B', 'C', 'D', 'E', 'F'].map(value => (
                                                    <div className="col-lg-4" key={'center'+value} >
                                                        <div className="white-rounded-container login-stats-box mb-20px">
                                                            <p className="login-stats-title">Center {value1}-{value}&nbsp;<span className="icon-angry"><FaAngry /></span></p>
                                                            <p className="login-stats-helper">(Compared to previous day 12 <IoCaretUp />)</p>
                                                            <p className="login-stats-value">99,999,999</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <button type="button" onClick={() => setShowGuide(true)} className="dms-btn dms-btn-s dms-btn-outline px-3 align-self-center" style={{ position: 'fixed', right: '20px', bottom: '20px'}}>?</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Login;
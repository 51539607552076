import { useEffect, useRef, useState } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import { GoPlusCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { TbQrcode } from "react-icons/tb";
import Footer from "../Components/Footer";
import ShelterSuppliesDetailsModal from "./ShelterSuppliesDetailsModal";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import useFetchWithParameters from "../useFetchWithParameters";
import ApiHelper from "../Helpers/ApiHelper";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import PreviewImageModal from "../Components/Modals/PreviewImageModal";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }
const optionsInOutStatus = [
    { value: 'IN', label: 'IN' },
    { value: 'OUT', label: 'OUT' },
]

const ShelterDetails = () => {
    const { id } = useParams();

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getFAC?facilityId=' + id
    const allListApiUrl = process.env.REACT_APP_BASE_API_URL + '/cfg/getDisasterCdLst';
    const victimsApiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getAcptVctmLst';
    const mealHistoryApiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getMealSrvHist'
    const supplyHistoryApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getSplyLst'
    const supplyHistoryApiUrl2 = process.env.REACT_APP_BASE_API_URL + '/fac/getPayoutSPLYHist'
    const deploymentApiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getInvlvUsrLst'
    //const inOutHistoryApiUrl = process.env.REACT_APP_BASE_API_URL+'/sply/getFacSplyHist'
    const [fileListApiUrl, setFileListApiUrl] = useState(process.env.REACT_APP_BASE_API_URL + '/fac/getFileLst?facilityId=' + id + '&reqPageSize=10000&reqPageNo=1')

    const uploadDrawingFileRef = useRef()
    const uploadBuilingFileRef = useRef()

    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isVisiblePreviewModal, setIsVisiblePreviewModal] = useState(false)
    const [isVisibleLeave, setIsVisibleLeave] = useState(false)
    const [isVisibleSaveExcelVictim, setIsVisibleSaveExcelVictim] = useState(false)
    const [isVisibleSaveExcelDeployment, setIsVisibleSaveExcelDeployment] = useState(false)
    const [isVisibleSaveExcelSupplies, setIsVisibleSaveExcelSupplies] = useState(false)
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [successFile, setSuccessFile] = useState(null);
    const [errorFile, setErrorFile] = useState(null);

    const [selectedLeave, setSelectedLeave] = useState(null);
    const [isLoadingLeave, setIsLoadingLeave] = useState(false);
    const [successLeave, setSuccessLeave] = useState(null);
    const [errorLeave, setErrorLeave] = useState(null);

    const [years, setYears] = useState([])
    const [allDisasters, setAllDisasters] = useState([])
    const [disastersVictim, setDisastersVictim] = useState([])
    const [disastersDeployment, setDisastersDeployment] = useState([])
    const [disastersMealHistory, setDisastersMealHistory] = useState([])
    const [disastersSupplyHistory, setDisastersSupplyHistory] = useState([])
    const [disastersSupplyHistory2, setDisastersSupplyHistory2] = useState([])

    const [name, setName] = useState('')
    const [type, setType] = useState(null)
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [coordinateX, setCoordinateX] = useState('')
    const [coordinateY, setCoordinateY] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [deptIncharge, setDeptIncharge] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [faxNumber, setFaxNumber] = useState('')
    const [repName, setRepName] = useState('')
    const [repPhoneNumber, setRepPhoneNumber] = useState('')
    const [ownerName, setOwnerName] = useState('')
    const [ownerPhoneNumber, setOwnerPhoneNumber] = useState('')
    const [usableArea, setUsableArea] = useState('')
    const [maximumCapacity, setMaximumCapacity] = useState('')
    const [currentVictimCount, setCurrentVictimCount] = useState('')
    const [files, setFiles] = useState([]);

    const [drawingFileName, setDrawingFileName] = useState('')
    const [buildingFileName, setBuildingFileName] = useState('')
    const [previewImage, setPreviewImage] = useState(null)

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])
    const [roles, setRoles] = useState([])

    const [itemTypes, setItemTypes] = useState([])

    const [supplyItemCategories1, setSupplyItemCategories1] = useState([])
    const [supplyItemCategories2, setSupplyItemCategories2] = useState([])
    const [supplyItemCategories3, setSupplyItemCategories3] = useState([])
    const [supplyItemCategories4, setSupplyItemCategories4] = useState([])

    const [inOutItemCategories1, setInOutItemCategories1] = useState([])
    const [inOutItemCategories2, setInOutItemCategories2] = useState([])
    const [inOutItemCategories3, setInOutItemCategories3] = useState([])
    const [inOutItemCategories4, setInOutItemCategories4] = useState([])

    const [victimsParameters, setVictimsParameters] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [victims, setVictims] = useState([])
    const [victimsTotalCount, setVictimsTotalCount] = useState(null);
    const [victimsFilters, setVictimsFilters] = useState({})

    const [mealHistoryParameters, setMealHistoryParameters] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [mealHistories, setMealHistories] = useState([])
    const [mealHistoryTotalCount, setMealHistoryTotalCount] = useState(null);
    const [mealHistoryFilters, setMealHistoryFilters] = useState({})

    const [supplyHistoryParameters, setSupplyHistoryParameters] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const [supplyHistoryParameters2, setSupplyHistoryParameters2] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const [deploymentParameters, setDeploymentParameters] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [supplyHistories2, setSupplyHistories2] = useState([])
    const [supplyHistoryTotalCount2, setSupplyHistoryTotalCount2] = useState(null);
    const [supplyHistoryFilters2, setSupplyHistoryFilters2] = useState({})

    const [supplyHistories, setSupplyHistories] = useState([])
    const [supplyHistoryTotalCount, setSupplyHistoryTotalCount] = useState(null);
    const [supplyHistoryFilters, setSupplyHistoryFilters] = useState({})


    const [deployments, setDeployments] = useState([])
    const [deploymentTotalCount, setDeploymentTotalCount] = useState(null);
    const [deploymentFilters, setDeploymentFilters] = useState({})

    // const [inOutHistoryParameters, setInOutHistoryParameters] = useState({
    //     facilityId: id,
    //     reqPageNo: 1,
    //     reqPageSize: 10,
    // })
    // const [inOutHistories, setInOutHistories] = useState([])
    // const [inOutHistoryTotalCount, setInOutHistoryTotalCount] = useState(null);
    // const [inOutHistoryFilters, setInOutHistoryFilters] = useState({})

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);
    const { fetchResponse: fileListFetchResponse } = useFetch(fileListApiUrl);
    const { fetchResponse: victimsFetchResponse, fetchIsLoading: victimsFetchIsLoading, fetchError: victimsFetchError } = useFetchWithParameters(victimsApiUrl, victimsParameters);
    const { fetchResponse: mealHistoryFetchResponse } = useFetchWithParameters(mealHistoryApiUrl, mealHistoryParameters);
    const { fetchResponse: supplyHistoryFetchResponse, fetchIsLoading: supplyFetchIsLoading, fetchError: supplyFetchError } = useFetchWithParameters(supplyHistoryApiUrl, supplyHistoryParameters);

    const { fetchResponse: supplyHistoryFetchResponse2, fetchIsLoading: supplyFetchIsLoading2, fetchError: supplyFetchError2 } = useFetchWithParameters(supplyHistoryApiUrl2, supplyHistoryParameters2);

    const { fetchResponse: deploymentFetchResponse, fetchIsLoading: deploymentFetchIsLoading, fetchError: deploymentFetchError } = useFetchWithParameters(deploymentApiUrl, deploymentParameters);

    // const { fetchResponse: inOutHistoryFetchResponse, fetchIsLoading: inOutFetchIsLoading, fetchError: inOutFetchError } = useFetchWithParameters(inOutHistoryApiUrl, inOutHistoryParameters);

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
        ApiHelper.loadDropdown('groupCode=D003&codeDepth=1', setFacilityTypes)
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
        ApiHelper.loadDropdown('groupCode=D006&codeDepth=1', setRoles)
    }, [])

    useEffect(() => {
        if (fetchResponse?.resultObject) {
            setName(fetchResponse?.resultObject?.name)
            setZipCode(fetchResponse?.resultObject?.zipCd)
            setMainAddress(fetchResponse?.resultObject?.addr)
            setDetailedAddress(fetchResponse?.resultObject?.addrDtl)
            setCoordinateX(fetchResponse?.resultObject?.xcord)
            setCoordinateY(fetchResponse?.resultObject?.ycord)
            setLatitude(fetchResponse?.resultObject?.lat)
            setLongitude(fetchResponse?.resultObject?.lot)
            setDeptIncharge(fetchResponse?.resultObject?.deptCharge)
            setPhoneNumber(fetchResponse?.resultObject?.telno)
            setFaxNumber(fetchResponse?.resultObject?.faxno)
            setRepName(fetchResponse?.resultObject?.reprMgrName)
            setRepPhoneNumber(fetchResponse?.resultObject?.reprMgrTelno)
            setOwnerName(fetchResponse?.resultObject?.ownerName)
            setOwnerPhoneNumber(fetchResponse?.resultObject?.ownerTelno)
            setUsableArea(fetchResponse?.resultObject?.acceptableArea)
            setMaximumCapacity(fetchResponse?.resultObject?.maxCapacity)
            setCurrentVictimCount(fetchResponse?.resultObject?.currentVictimCount)
        }
    }, [fetchResponse])

    useEffect(() => {
        if (allListFetchResponse) {
            const years = []
            const disasters = []
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt) {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt) {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }

                if (value?.name) {
                    if (!disasters.find(disaster => disaster.value == value.disasterCd)) disasters.push({ value: value.disasterCd, label: value.name, year: value?.startDt?.substring(0, 4) })
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({ value: value, label: value })))
            setAllDisasters(disasters)
        }
    }, [allListFetchResponse])

    useEffect(() => {
        if (victimsFilters?.year)
        {
            setDisastersVictim(allDisasters.filter(value => value.year == victimsFilters?.year))
        }
        else
        {
            setDisastersVictim([])
        }
    }, [victimsFilters?.year])

    useEffect(() => {
        if (deploymentFilters?.year)
        {
            setDisastersDeployment(allDisasters.filter(value => value.year == deploymentFilters?.year))
        }
        else
        {
            setDisastersDeployment([])
        }
    }, [deploymentFilters?.year])

    useEffect(() => {
        if (mealHistoryFilters?.year)
        {
            setDisastersMealHistory(allDisasters.filter(value => value.year == mealHistoryFilters?.year))
        }
        else
        {
            setDisastersMealHistory([])
        }
    }, [mealHistoryFilters?.year])

    useEffect(() => {
        if (supplyHistoryFilters?.year)
        {
            setDisastersSupplyHistory(allDisasters.filter(value => value.year == supplyHistoryFilters?.year))
        }
        else
        {
            setDisastersSupplyHistory([])
        }
    }, [supplyHistoryFilters?.year])

    useEffect(() => {
        if (supplyHistoryFilters2?.year)
        {
            setDisastersSupplyHistory2(allDisasters.filter(value => value.year == supplyHistoryFilters2?.year))
        }
        else
        {
            setDisastersSupplyHistory2([])
        }
    }, [supplyHistoryFilters2?.year])

    useEffect(() => {
        if (fileListFetchResponse?.resultList) {
            setFiles(fileListFetchResponse?.resultList)
        }
    }, [fileListFetchResponse])

    useEffect(() => {
        if (victimsFetchResponse) {
            setVictimsTotalCount(victimsFetchResponse?.totalCount);
            setVictims(victimsFetchResponse?.resultList ?? []);
        }
    }, [victimsFetchResponse])

    useEffect(() => {
        if (mealHistoryFetchResponse) {
            setMealHistoryTotalCount(mealHistoryFetchResponse?.totalCount);
            setMealHistories(mealHistoryFetchResponse?.resultList ?? []);
        }
    }, [mealHistoryFetchResponse])

    useEffect(() => {
        if (supplyHistoryFetchResponse) {
            setSupplyHistoryTotalCount(supplyHistoryFetchResponse?.totalCount);
            setSupplyHistories(supplyHistoryFetchResponse?.resultList ?? []);
        }
    }, [supplyHistoryFetchResponse])


    useEffect(() => {
        if (supplyHistoryFetchResponse2) {
            setSupplyHistoryTotalCount2(supplyHistoryFetchResponse2?.totalCount);
            setSupplyHistories2(supplyHistoryFetchResponse2?.resultList ?? []);
        }
    }, [supplyHistoryFetchResponse2])

    useEffect(() => {
        if (deploymentFetchResponse) {
            setDeploymentTotalCount(deploymentFetchResponse?.totalCount);
            setDeployments(deploymentFetchResponse?.resultList ?? []);
        }
    }, [deploymentFetchResponse])


    // useEffect(() => {
    //     if (inOutHistoryFetchResponse)
    //     {
    //         setInOutHistoryTotalCount(inOutHistoryFetchResponse?.totalCount);
    //         setInOutHistories(inOutHistoryFetchResponse?.resultList ?? []);
    //     }
    // }, [inOutHistoryFetchResponse])

    useEffect(() => {
        if (fetchResponse?.resultObject && areas1) setType(facilityTypes.find(value => value.value == fetchResponse?.resultObject?.classCd))
    }, [fetchResponse, facilityTypes])

    useEffect(() => {
        if (fetchResponse?.resultObject && areas1) setArea1(areas1.find(value => value.value == fetchResponse?.resultObject?.area1Cd))
    }, [fetchResponse, areas1])

    useEffect(() => {
        if (fetchResponse?.resultObject && areas2) setArea2(areas2.find(value => value.value == fetchResponse?.resultObject?.area2Cd))
    }, [fetchResponse, areas2])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!supplyHistoryFilters?.type) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setSupplyItemCategories2([])
        setSupplyItemCategories3([])
        setSupplyItemCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1=' + supplyHistoryFilters?.type, setSupplyItemCategories1)
    }, [supplyHistoryFilters?.type])

    useEffect(() => {
        if (!supplyHistoryFilters?.cat1) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setSupplyItemCategories3([])
        setSupplyItemCategories4([])

        var cat1 = supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1=' + supplyHistoryFilters?.type + '&codeLev2=' + cat1, setSupplyItemCategories2)
    }, [supplyHistoryFilters?.cat1])

    useEffect(() => {
        if (!supplyHistoryFilters?.cat2) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });

        setSupplyItemCategories4([])

        var cat1 = supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)
        var cat2 = supplyHistoryFilters?.cat2.substring(supplyHistoryFilters?.cat1.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1=' + supplyHistoryFilters?.type + '&codeLev2=' + cat1 + '&codeLev3=' + cat2, setSupplyItemCategories3)
    }, [supplyHistoryFilters?.cat2])

    useEffect(() => {
        if (!supplyHistoryFilters?.cat3) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)
        var cat2 = supplyHistoryFilters?.cat2.substring(supplyHistoryFilters?.cat1.length)
        var cat3 = supplyHistoryFilters?.cat3.substring(supplyHistoryFilters?.cat2.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1=' + supplyHistoryFilters?.type + '&codeLev2=' + cat1 + '&codeLev3=' + cat2 + '&codeLev4=' + cat3, setSupplyItemCategories4)
    }, [supplyHistoryFilters?.cat3])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!zipCode) {
            setError('Enter choose an address')
            return
        }

        setIsLoading(true)

        var formData = {
            facilityId: id,
            name: name,
            classCd: type?.value,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            area1Cd: area1?.value,
            area2Cd: area2?.value,
            lat: latitude,
            lot: longitude,
            deptCharge: deptIncharge,
            acceptableArea: usableArea,
            maxCapacity: maximumCapacity,
            telno: phoneNumber,
            faxno: faxNumber,
            reprMgrTelno: repPhoneNumber,
            reprMgrName: repName,
            ownerTelno: ownerPhoneNumber,
            ownerName: ownerName,
            xcord: coordinateX,
            ycord: coordinateY,
        }

        fetch(process.env.REACT_APP_BASE_API_URL + '/fac/updateFAC', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            })
    }

    const handleVictimsFilterSubmit = (e) => {
        e.preventDefault();

        setVictimsParameters(current => ({ ...current, ...victimsFilters }))
    }

    const initializeVictimsFilter = () => {
        setVictimsFilters({});
        setVictimsParameters(current => ({ facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize }))
    }

    const handleDeploymentFilterSubmit = (e) => {
        e.preventDefault();

        setDeploymentParameters(current => ({ ...current, ...deploymentFilters }))
    }

    const initializeDeploymentFilter = () => {
        setDeploymentFilters({});
        setDeploymentParameters(current => ({ facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize }))
    }

    const handleMealHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setMealHistoryParameters(current => ({ ...current, ...mealHistoryFilters }))
    }

    const initializeMealHistoryFilter = () => {
        setMealHistoryFilters({});
        setMealHistoryParameters(current => ({ facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize }))
    }

    const handleSupplyHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setSupplyHistoryParameters(current => ({
            ...current, 
            ...(supplyHistoryFilters?.type ? {kindCd: supplyHistoryFilters?.type} : null),
            ...(supplyHistoryFilters?.cat1 ? {cat1Cd: supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)} : null),
            ...(supplyHistoryFilters?.cat2 ? {cat2Cd: supplyHistoryFilters?.cat2.substring(supplyHistoryFilters?.cat1.length)} : null),
            ...(supplyHistoryFilters?.cat3 ? {cat3Cd: supplyHistoryFilters?.cat3.substring(supplyHistoryFilters?.cat2.length)} : null),
            ...(supplyHistoryFilters?.cat4 ? {cat4Cd: supplyHistoryFilters?.cat4.substring(supplyHistoryFilters?.cat3.length)} : null),
        }))
    }

    const initializeSupplyHistoryFilter = () => {
        setSupplyHistoryFilters({});
        setSupplyHistoryParameters(current => ({ facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize }))
    }

    const handleSupplyHistory2FilterSubmit = (e) => {
        e.preventDefault();

        setSupplyHistoryParameters2(current => ({ ...current, ...supplyHistoryFilters2 }))
    }

    const initializeSupplyHistory2Filter = () => {
        setSupplyHistoryFilters2({});
        setSupplyHistoryParameters2(current => ({ facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize }))
    }

    const handleFileChange = (e, drawTypeCd) => {
        var file = e.target.files[0];
        if (!file) return

        setErrorFile(null)
        setSuccessFile(null)
        setIsLoadingFile(true)

        if (drawTypeCd == '01') setDrawingFileName(file?.name)
        else setBuildingFileName(file?.name)

        var formData = new FormData();
        formData.append("facilityId", id);
        formData.append("drawTypeCd", drawTypeCd);
        formData.append("addFile", file);

        fetch(process.env.REACT_APP_BASE_API_URL + '/fac/addFile', {
            method: 'POST',
            body: formData,
            headers: {
                //"Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccessFile(data.responseMessage ?? 'Success')

                    setFileListApiUrl(process.env.REACT_APP_BASE_API_URL + '/fac/getFileLst?facilityId=' + id + '&reqPageSize=10000&reqPageNo=1&forceRefresh=' + Math.random().toString(36).substring(2, 10))
                }
                else {
                    setErrorFile(data.responseMessage ?? 'Error in submitting data');

                    if (drawTypeCd == '01' && uploadDrawingFileRef?.current) {
                        uploadDrawingFileRef.current.value = '';
                    }
                    if (drawTypeCd == '02' && uploadBuilingFileRef?.current) {
                        uploadBuilingFileRef.current.value = '';
                    }
                }

                setIsLoadingFile(false);

                if (drawTypeCd == '01') setDrawingFileName('')
                else setBuildingFileName('')
            })
            .catch(error => {
                setIsLoadingFile(false);

                if (drawTypeCd == '01') setDrawingFileName('')
                else setBuildingFileName('')

                if (drawTypeCd == '01' && uploadDrawingFileRef?.current) {
                    uploadDrawingFileRef.current.value = '';
                }
                if (drawTypeCd == '02' && uploadBuilingFileRef?.current) {
                    uploadBuilingFileRef.current.value = '';
                }
            })
    }

    const handleDeleteFile = (id) => {
        setErrorFile(null)
        setSuccessFile(null)
        setIsLoadingFile(true)

        var formData = {
            delFileId: id,
        }

        fetch(process.env.REACT_APP_BASE_API_URL + '/fac/delFile', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccessFile(data.responseMessage ?? 'Success')

                    setFiles(current => current.filter(value => value.fileId != id))
                }
                else {
                    setErrorFile(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingFile(false);
            })
            .catch(error => {
                setErrorFile(error.message);
                setIsLoadingFile(false);
            })
    }

    const handlePreviewImage = (id) => {
        setIsVisiblePreviewModal(true);

        fetch(process.env.REACT_APP_BASE_API_URL + '/fac/getImage?fileId=' + id)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }

                return response.blob();
            })
            .then(data => {
                setPreviewImage(window.URL.createObjectURL(new Blob([data])))
            })
            .catch(error => {
            })
    }

    const handleLeave = () => {
        setErrorLeave(null)
        setSuccessLeave(null)
        setIsLoadingLeave(true)
        
        console.log(selectedLeave)
        var formData = {
            userId: selectedLeave?.userId,
            facilityId: selectedLeave?.facilityId,
            statusCd: 'OUT'
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/userInout', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessLeave(data.responseMessage ?? 'Success')
                //setApiUrl(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMInfo?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))
            }
            else
            {
                setErrorLeave(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingLeave(false);
            setSelectedLeave(null)
        })
        .catch(error => {
            setErrorLeave(error.message);
            setIsLoadingLeave(false);
        })
    }

    const handleSaveExcelVictim = () => {
        var getParameters = victimsParameters ? new URLSearchParams(victimsParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/expAcptVctmXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const handleSaveExcelDeployment = () => {
        var getParameters = deploymentParameters ? new URLSearchParams(deploymentParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/expInvlvUsrLst?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const handleSaveExcelSupplies = () => {
        var getParameters = supplyHistoryParameters ? new URLSearchParams(supplyHistoryParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expSplyLst?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const [items, setItems] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }])
    const [selectedItems1, setSelectedItems1] = useState([])
    const [selectedItems2, setSelectedItems2] = useState([])
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const [isVisibleSuppliesDetailsModal, setIsVisibleSuppliesDetailsModal] = useState(false)
    const [isCheckedDirectInputCoordinates, setIsCheckedDirectInputCoordinates] = useState(false)
    const [isCheckedDirectInputLatLong, setIsCheckedDirectInputLatLong] = useState(false)

    const [facilityData, setFacilityData] = useState(null);
    const [error11, setError11] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/fac/getFAC?facilityId=${id}`);

                if (!response.ok) {
                    throw new Error('Error fetching data');
                }

                const data = await response.json();
                setFacilityData(data.resultObject);
            } catch (error) {
                setError11(error.message);
            }
        };

        fetchData();
    }, [id]);


    const handleCheckItem1 = (id) => {
        setSelectedItems1(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }

    const handleCheckItem2 = (id) => {
        setSelectedItems2(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }

    const handleFindDrawing = () => {

    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Shelter</h3>
                <Link to={'/shelter-management'} className="sidebar-link active">Shelter management</Link>
                <Link to={`/shelter-inquiry-board/${facilityData?.facilityId}`} className="sidebar-link">Shelter inquiry board</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">View shelter details</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">Modified: 2023-07-23 10:50:00</p>
                            <Link to="/shelter-management" className="dms-btn dms-btn-s dms-btn-outline">To List</Link>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="Shelter details">
                            <form onSubmit={handleSubmit}>
                                <div className="d-flex justify-content-end align-items-center mb-20px">
                                    <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoading ? 'disabled' : ''}`}>Modify</button>
                                </div>

                                <div className="white-rounded-container mb-20px">
                                    {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                                    {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                                    <div className="form-heading"><h5>Details</h5></div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Shelter name<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                    placeholder="Please enter a name"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Facility Type<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    required={true}
                                                    placeholder={'Select'}
                                                    value={type ?? null}
                                                    onChange={(e) => setType(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">Address<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    value={zipCode}
                                                    placeholder="Please choose address"
                                                    disabled={true} />
                                            </div>
                                            <div className="dms-input-4">
                                                <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    value={mainAddress}
                                                    placeholder="Please choose address"
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    onChange={e => setDetailedAddress(e.target.value)}
                                                    value={detailedAddress}
                                                    placeholder="Enter detailed address"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Location</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Select Province'}
                                                    value={area1 ?? null}
                                                    onChange={(e) => setArea1(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="dms-input-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'Select District'}
                                                    value={area2 ?? null}
                                                    onChange={(e) => setArea2(e)}
                                                    options={areas2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Coordinates</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setCoordinateX(e.target.value)}
                                                    value={coordinateX}
                                                    placeholder="X: 129308.1283" />
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setCoordinateY(e.target.value)}
                                                    value={coordinateY}
                                                    placeholder="Y: 129308.1283" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Latitude/Longitude</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setLatitude(e.target.value)}
                                                    value={latitude}
                                                    placeholder="Latitude: 12.1283" />
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setLongitude(e.target.value)}
                                                    value={longitude}
                                                    placeholder="Longitude: 129.1283" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Responsible department</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setDeptIncharge(e.target.value)}
                                                    value={deptIncharge}
                                                    placeholder="Please enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Usable area<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <div className="input-group">
                                                    <FormControl
                                                        onChange={e => setUsableArea(e.target.value)}
                                                        value={usableArea}
                                                        placeholder="Enter usable area"
                                                        required={true} />
                                                    <span className="input-group-text">m<sup>2</sup></span>
                                                </div>
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">Maximum Capacity<span className="label-ast">*</span></div>
                                            </div>
                                            <div className="dms-input-4">
                                                <div className="input-group">
                                                    <FormControl
                                                        onChange={e => setMaximumCapacity(e.target.value)}
                                                        value={maximumCapacity}
                                                        placeholder="Enter maximum capacity"
                                                        required={true} />
                                                    <span className="input-group-text">Person</span>
                                                </div>
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center text-orange">(Current: {currentVictimCount})</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Telephone</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                    placeholder="Please enter phone number" />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">Fax Number</div>
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setFaxNumber(e.target.value)}
                                                    value={faxNumber}
                                                    placeholder="Please enter fax number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Principal officer<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setRepName(e.target.value)}
                                                    value={repName}
                                                    placeholder="Please enter a name"
                                                    required={true} />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">Principal officer phone number</div>
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setRepPhoneNumber(e.target.value)}
                                                    value={repPhoneNumber}
                                                    placeholder="Please enter phone number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">Facility officer<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setOwnerName(e.target.value)}
                                                    value={ownerName}
                                                    placeholder="Please enter a name"
                                                    required={true} />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">Facility officer phone number<span className="label-ast">*</span></div>
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setOwnerPhoneNumber(e.target.value)}
                                                    value={ownerPhoneNumber}
                                                    placeholder="Please enter phone number"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Attachments</h5></div>

                                {successFile ? <Alert variant="success" style={{ marginTop: -20 }}>{successFile ?? 'Success'}</Alert> : null}
                                {errorFile ? <Alert variant="danger" style={{ marginTop: -20 }}>{errorFile ?? 'Error'}</Alert> : null}

                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-2">Architechtural drawings</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl value={drawingFileName} readOnly={true} />
                                            <div className="input-helper">Upload up to 1 images. Uploadable extensions: JPG, JPEG, PNG</div>
                                        </div>
                                        <div className="dms-input-4">
                                            <label htmlFor="upload-file-drawing" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active fw-regular">
                                                {!drawingFileName ? <GoPlusCircle size="22px" /> : null}
                                                {drawingFileName ? 'Uploading...' : 'Find File'}
                                                <input ref={uploadDrawingFileRef} type="file" accept="image/jpg,image/jpeg,image/png" id="upload-file-drawing" hidden onChange={(e) => { if (!isLoadingFile) handleFileChange(e, '01') }} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-2">Building front view</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl value={buildingFileName} readOnly={true} />
                                            <div className="input-helper">Upload up to 4 images. Uploadable extensions: JPG, JPEG, PNG</div>
                                        </div>
                                        <div className="dms-input-4">
                                            <label htmlFor="upload-file-building" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active fw-regular">
                                                {!buildingFileName ? <GoPlusCircle size="22px" /> : null}
                                                {buildingFileName ? 'Uploading...' : 'Find File'}
                                                <input ref={uploadBuilingFileRef} type="file" accept="image/jpg,image/jpeg,image/png" id="upload-file-building" hidden onChange={(e) => { if (!isLoadingFile) handleFileChange(e, '02') }} />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-100">
                                            <table className="table dms-table-1">
                                                <thead>
                                                    <tr>
                                                        <th>Category</th>
                                                        <th>Filename</th>
                                                        <th>Preview</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {files.map(item => (
                                                        <tr key={item.fileId}>
                                                            <td style={{ width: "150px" }}>{item.drawType}</td>
                                                            <td>
                                                                {item.fileName}
                                                                <button type="button" onClick={() => handleDeleteFile(item.fileId)} className={`dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex ms-2 ${isLoadingFile ? 'disabled' : ''}`}><IoClose size="16px" /> Delete</button>
                                                            </td>
                                                            <td style={{ width: "100px" }}>
                                                                <button type="button" onClick={() => handlePreviewImage(item.fileId)} className={`dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3 ${isLoadingFile ? 'disabled' : ''}`}>Preview</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab2" title="Victims">
                            <form onSubmit={handleVictimsFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-20px">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select Year/Disasters</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={'Select year'}
                                                    value={victimsFilters?.year ? { label: victimsFilters?.year } : null}
                                                    onChange={(e) => setVictimsFilters(current => ({ ...current, year: e.value }))}
                                                    isSearchable={false}
                                                    options={years}
                                                />
                                            </div>
                                            <div className="col-lg-9">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={'All disasters'}
                                                    value={victimsFilters?.disasterCd ? disastersVictim.find(value => value.value == victimsFilters.disasterCd) ?? null : null}
                                                    onChange={(e) => setVictimsFilters(current => ({ ...current, disasterCd: e.value }))}
                                                    isSearchable={false}
                                                    options={disastersVictim}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Status</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"All"}
                                                    value={victimsFilters?.statusCd ? optionsInOutStatus.find(value => value.value == victimsFilters?.statusCd) : null}
                                                    onChange={(e) => setVictimsFilters(current => ({ ...current, statusCd: e.value }))}
                                                    isSearchable={false}
                                                    options={optionsInOutStatus}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Name</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-5">
                                                <FormControl
                                                    value={victimsFilters?.userName ?? ''}
                                                    onChange={e => setVictimsFilters(current => ({ ...current, userName: e.target.value }))}
                                                    placeholder="Please enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeVictimsFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </form>

                            {successLeave ? <Alert variant="success">{successLeave ?? 'Success'}</Alert> : null}
                            {errorLeave ? <Alert variant="danger">{errorLeave ?? 'Error'}</Alert> : null}

                            {victimsTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {victimsTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {victimsFetchError ? <p className="search-result text-orange">{victimsFetchError}</p> : null}
                                            {victimsFetchIsLoading ? <p className="search-result">Loading data</p> :
                                                victimsTotalCount !== null ? <p className="search-result">Search Result <span>{victimsTotalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="button" onClick={() => setIsVisibleSaveExcelVictim(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {victimsTotalCount !== null && victimsTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th><FormCheck checked={selectedItems1.length == items.length} onClick={() => setSelectedItems1(selectedItems1.length == items.length ? [] : items.map(value => value.userId))} /></th>
                                            <th>Name</th>
                                            <th>Social Number</th>
                                            <th>Residential address</th>
                                            <th>Phone number</th>
                                            <th>Disaster</th>
                                            <th>Admission time</th>
                                            <th>Discharge time</th>
                                            <th>Status</th>
                                            <th>Leave</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {victims.map(item => (
                                            <tr key={item.eventDt + item.recvFacAddress} className={selectedItems1.includes(item.userId) ? 'row-checked' : ''}>
                                                <td><FormCheck checked={selectedItems1.includes(item.userId)} onChange={() => handleCheckItem1(item.userId)} /></td>
                                                <td>{item.name}</td>
                                                <td>{item.regNo}</td>
                                                <td>{item.addr}</td>
                                                <td>{item.telNo}</td>
                                                <td>{item.disName}</td>
                                                <td>{item.walkedInDt}</td>
                                                <td>{item.walkedOutDt}</td>
                                                <td className={item.walkedOutDt ? '' : 'text-orange'}>{item.status}</td>
                                                <td style={{ width: "120px" }}><button type="button" onClick={() => {setIsVisibleLeave(true); setSelectedLeave({userId: item?.userId, facilityId: item?.facilityId})}} className={`dms-btn dms-btn-s dms-btn-sm dms-btn-outline ${item.walkedOutDt ? 'disabled' : ''}`}>Leave</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {victimsTotalCount !== null && victimsTotalCount !== 0 && Math.ceil(victimsTotalCount / victimsParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setVictimsParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(victimsParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(victimsTotalCount / victimsParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab3" title="Deployment">
                            <div className="d-flex justify-content-end align-items-center mb-20px">
                                <Link to="/shelter-volunteer-register" className="dms-btn dms-btn-s dms-btn-outline">Create a request</Link>
                            </div>

                            <form onSubmit={handleDeploymentFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-20px">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select Year/Disasters</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={'Select year'}
                                                    value={deploymentFilters?.year ? { label: deploymentFilters?.year } : null}
                                                    onChange={(e) => setDeploymentFilters(current => ({ ...current, year: e.value }))}
                                                    isSearchable={false}
                                                    options={years}
                                                />
                                            </div>
                                            <div className="col-lg-9">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={'All disasters'}
                                                    value={deploymentFilters?.disasterCd ? disastersDeployment.find(value => value.value == deploymentFilters.disasterCd) ?? null : null}
                                                    onChange={(e) => setDeploymentFilters(current => ({ ...current, disasterCd: e.value }))}
                                                    isSearchable={false}
                                                    options={disastersDeployment}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Category / Name</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={'Select role'}
                                                    value={deploymentFilters?.userKind ? { label: deploymentFilters?.userKind } : null}
                                                    onChange={(e) => setDeploymentFilters(current => ({ ...current, userKind: e.value }))}
                                                    isSearchable={false}
                                                    options={roles}
                                                />
                                            </div>
                                            <div className="col-lg-9">
                                                <FormControl 
                                                    value={deploymentFilters?.userName ?? ''}
                                                    onChange={e => setDeploymentFilters(current => ({...current, userName: e.target.value}))}
                                                    placeholder="Please enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeDeploymentFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </form>

                            {deploymentTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {deploymentTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { deploymentFetchError ? <p className="search-result text-orange">{deploymentFetchError}</p> : null }
                                            { deploymentFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                deploymentTotalCount !== null ? <p className="search-result">Search Result <span>{deploymentTotalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="button" onClick={() => setIsVisibleSaveExcelDeployment(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {deploymentTotalCount !== null && deploymentTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th><FormCheck checked={selectedItems2.length == items.length} onClick={() => setSelectedItems2(selectedItems2.length == items.length ? [] : items.map(value => value.userId))} /></th>
                                            <th>Category</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Role</th>
                                            <th>Disaster</th>
                                            <th>Admission time</th>
                                            <th>Discharge time</th>
                                            <th>Status</th>
                                            <th>Leave</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deployments.map(value => (
                                            <tr key={value.userId} className={selectedItems2.includes(value.userId) ? 'row-checked' : ''}>
                                                <td><FormCheck checked={selectedItems2.includes(value.userId)} onChange={() => handleCheckItem2(value.userId)} /></td>
                                                <td>{value.userType}</td>
                                                <td>{value.userName}</td>
                                                <td>{value.telNo}</td>
                                                <td>{value.userRole}</td>
                                                <td>{value.disasterName}</td>
                                                <td>{value.enterDt}</td>
                                                <td>{value.leaveDt}</td>
                                                <td className={value.leaveDt ? '' : 'text-orange'}>{value.status}</td>
                                                <td style={{ width: "120px" }}><button type="button" onClick={() => {setIsVisibleLeave(true); setSelectedLeave({userId: value?.userId, facilityId: value?.facilityId})}} className={`dms-btn dms-btn-s dms-btn-sm dms-btn-outline ${value.leaveDt ? 'disabled' : ''}`}>Leave</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {deploymentTotalCount !== null && deploymentTotalCount !== 0 && Math.ceil(deploymentTotalCount / deploymentParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setDeploymentParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(deploymentParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(deploymentTotalCount / deploymentParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab4" title="Supplies inventory">
                            <div className="d-flex justify-content-end align-items-center mb-20px">
                                <Link to="/shelter-supplies-register" className="dms-btn dms-btn-s dms-btn-outline">Create a request</Link>
                            </div>

                            <form onSubmit={handleSupplyHistoryFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Item Type</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"All"}
                                                    value={supplyHistoryFilters?.type ? itemTypes.find(value => value.value == supplyHistoryFilters?.type) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, type: e.value}))}
                                                    isSearchable={false}
                                                    options={itemTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Item Category</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"All"}
                                                    value={supplyHistoryFilters?.cat1 ? supplyItemCategories1.find(value => value.value == supplyHistoryFilters?.cat1) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat1: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"All"}
                                                    value={supplyHistoryFilters?.cat2 ? supplyItemCategories2.find(value => value.value == supplyHistoryFilters?.cat2) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat2: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories2}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"All"}
                                                    value={supplyHistoryFilters?.cat3 ? supplyItemCategories3.find(value => value.value == supplyHistoryFilters?.cat3) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat3: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories3}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"All"}
                                                    value={supplyHistoryFilters?.cat4 ? supplyItemCategories4.find(value => value.value == supplyHistoryFilters?.cat4) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat4: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories4}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeSupplyHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </form>

                            {supplyHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {supplyHistoryTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { supplyFetchError ? <p className="search-result text-orange">{supplyFetchError}</p> : null }
                                            { supplyFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                supplyHistoryTotalCount !== null ? <p className="search-result">Search Result <span>{supplyHistoryTotalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{label: 'View By '+supplyHistoryParameters.reqPageSize}}
                                                onChange={(e) => setSupplyHistoryParameters(current => ({...current, reqPageSize: e.value}))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: 'View By 10' },
                                                    { value: 20, label: 'View By 20' },
                                                    { value: 30, label: 'View By 30' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcelSupplies(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {supplyHistoryTotalCount !== null && supplyHistoryTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>Item Type</th>
                                            <th>Category 1</th>
                                            <th>Category 2</th>
                                            <th>Category 3</th>
                                            <th>Category 4</th>
                                            <th>Inventory</th>
                                            <th>View Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {supplyHistories.map(item => (
                                            <tr key={item.splyId}>
                                                <td>{item.kind}</td>
                                                <td>{item.cat1}</td>
                                                <td>{item.cat2}</td>
                                                <td>{item.cat3}</td>
                                                <td>{item.cat4}</td>
                                                <td>{item.supplyCount}</td>
                                                <td style={{ width: "120px" }}><Link to={"/inventory-details/" + item.splyId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                            
                            {supplyHistoryTotalCount !== null && supplyHistoryTotalCount !== 0 && Math.ceil(supplyHistoryTotalCount / supplyHistoryParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setSupplyHistoryParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(supplyHistoryParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(supplyHistoryTotalCount / supplyHistoryParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab5" title="Distribution">
                            <div className="white-rounded-container mb-20px">
                                <Tabs defaultActiveKey="tab-d1" id="tabs-example" className="underline" fill>
                                    <Tab eventKey="tab-d1" title="Meal">
                                        <form onSubmit={handleMealHistoryFilterSubmit} className="mb-20px">
                                            <div className="row mb-20px">
                                                <div className="col-lg-2 d-flex align-items-center">
                                                    <div className="dms-filter-label">Select Year/Disasters</div>
                                                </div>
                                                <div className="col-lg-10">
                                                    <div className="row gx-20px">
                                                        <div className="col-lg-3">
                                                            <ReactSelect
                                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                                classNamePrefix="select"
                                                                placeholder={'Select year'}
                                                                value={mealHistoryFilters?.year ? { label: mealHistoryFilters?.year } : null}
                                                                onChange={(e) => setMealHistoryFilters(current => ({ ...current, year: e.value }))}
                                                                isSearchable={false}
                                                                options={years}
                                                            />
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <ReactSelect
                                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                                classNamePrefix="select"
                                                                placeholder={'All disasters'}
                                                                value={mealHistoryFilters?.disasterCd ? disastersMealHistory.find(value => value.value == mealHistoryFilters.disasterCd) ?? null : null}
                                                                onChange={(e) => setMealHistoryFilters(current => ({ ...current, disasterCd: e.value }))}
                                                                isSearchable={false}
                                                                options={disastersMealHistory}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <button type="button" onClick={initializeMealHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                                <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                            </div>
                                        </form>

                                        {mealHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                                        {mealHistoryTotalCount !== null && mealHistoryTotalCount !== 0 ? (
                                            <table className="table dms-table-1">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Birthday</th>
                                                        <th>Disaster name</th>
                                                        <th>Meal time</th>
                                                        <th>Meal type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mealHistories.map(item => (
                                                        <tr key={item.disasterCd + item.mealedTime}>
                                                            <td>{item.name}</td>
                                                            <td>{item.userBday}</td>
                                                            <td>{item.disasterName}</td>
                                                            <td>{item.mealedTime}</td>
                                                            <td>{item.mealType}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : null}

                                        {mealHistoryTotalCount !== null && mealHistoryTotalCount !== 0 && Math.ceil(mealHistoryTotalCount / mealHistoryParameters.reqPageSize) > 1 ? (
                                            <ReactPaginate
                                                onPageChange={(e) => setMealHistoryParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                                forcePage={Math.max(mealHistoryParameters.reqPageNo - 1, 0)}
                                                pageCount={Math.ceil(mealHistoryTotalCount / mealHistoryParameters.reqPageSize)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                previousLabel={<LuChevronLeft size="20px" />}
                                                nextLabel={<LuChevronRight size="20px" />}
                                                breakLabel="..."
                                                containerClassName="pagination justify-content-center"
                                                pageClassName="page-item"
                                                previousClassName="page-item page-previous"
                                                nextClassName="page-item page-next"
                                                breakClassName="page-item"
                                                pageLinkClassName="page-link"
                                                activeClassName="active"
                                                previousLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                            />
                                        ) : null}
                                    </Tab>
                                    <Tab eventKey="tab-d2" title="Relief supplies">
                                        <form onSubmit={handleSupplyHistory2FilterSubmit} className="mb-20px">
                                            <div className="row mb-20px">
                                                <div className="col-lg-2 d-flex align-items-center">
                                                    <div className="dms-filter-label">Select Year/Disasters</div>
                                                </div>
                                                <div className="col-lg-10">
                                                    <div className="row gx-20px">
                                                        <div className="col-lg-3">
                                                            <ReactSelect
                                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                                classNamePrefix="select"
                                                                placeholder={'Select year'}
                                                                value={supplyHistoryFilters2?.year ? { label: supplyHistoryFilters2?.year } : null}
                                                                onChange={(e) => setSupplyHistoryFilters2(current => ({ ...current, year: e.value }))}
                                                                isSearchable={false}
                                                                options={years}
                                                            />
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <ReactSelect
                                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                                classNamePrefix="select"
                                                                placeholder={'All disasters'}
                                                                value={supplyHistoryFilters2?.disasterCd ? disastersSupplyHistory2.find(value => value.value == supplyHistoryFilters2.disasterCd) ?? null : null}
                                                                onChange={(e) => setSupplyHistoryFilters2(current => ({ ...current, disasterCd: e.value }))}
                                                                isSearchable={false}
                                                                options={disastersSupplyHistory2}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <button type="button" onClick={initializeSupplyHistory2Filter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                                <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                            </div>
                                        </form>

                                        {supplyHistoryTotalCount2 === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                                        {supplyHistoryTotalCount2 !== null && supplyHistoryTotalCount2 !== 0 ? (
                                            <table className="table dms-table-1">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Category</th>
                                                        <th>Subcategory</th>
                                                        <th>Recipient</th>
                                                        <th>Disaster</th>
                                                        <th>Receiving time</th>
                                                        <th>Quantity</th>
                                                        <th>Representative</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {supplyHistories2.map(item => (
                                                        <tr key={item.splyId}>
                                                            <td>{item.kindCd}</td>
                                                            <td>{item.cat1}</td>
                                                            <td>{item.cat2}</td>
                                                            <td>{item.recvName}</td>
                                                            <td>{item.disasterName}</td>
                                                            <td>{item.recvDt}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.chargeName}</td>
                                                            {/* <td style={{ width: "120px" }}><Link to={"/inventory-details/" + item.splyId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : null}

                                        {supplyHistoryTotalCount2 !== null && supplyHistoryTotalCount2 !== 0 && Math.ceil(supplyHistoryTotalCount2 / supplyHistoryParameters2.reqPageSize) > 1 ? (
                                            <ReactPaginate
                                                onPageChange={(e) => setSupplyHistoryParameters2(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                                forcePage={Math.max(supplyHistoryParameters2.reqPageNo - 1, 0)}
                                                pageCount={Math.ceil(supplyHistoryTotalCount2 / supplyHistoryParameters2.reqPageSize)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                previousLabel={<LuChevronLeft size="20px" />}
                                                nextLabel={<LuChevronRight size="20px" />}
                                                breakLabel="..."
                                                containerClassName="pagination justify-content-center"
                                                pageClassName="page-item"
                                                previousClassName="page-item page-previous"
                                                nextClassName="page-item page-next"
                                                breakClassName="page-item"
                                                pageLinkClassName="page-link"
                                                activeClassName="active"
                                                previousLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                            />
                                        ) : null}
                                    </Tab>
                                </Tabs>
                            </div>
                        </Tab>
                    </Tabs>
                </div>

                <Footer />
            </div>

            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress}
                setIsVisible={setIsVisibleAddress} />

            <SaveExcelModal handleSaveExcel={handleSaveExcelVictim} isVisible={isVisibleSaveExcelVictim} setIsVisible={setIsVisibleSaveExcelVictim} />
            <SaveExcelModal handleSaveExcel={handleSaveExcelDeployment} isVisible={isVisibleSaveExcelDeployment} setIsVisible={setIsVisibleSaveExcelDeployment} />
            <SaveExcelModal handleSaveExcel={handleSaveExcelSupplies} isVisible={isVisibleSaveExcelSupplies} setIsVisible={setIsVisibleSaveExcelSupplies} />
            <ShelterSuppliesDetailsModal isVisible={isVisibleSuppliesDetailsModal} setIsVisible={setIsVisibleSuppliesDetailsModal} />
            <PreviewImageModal image={previewImage} setImage={setPreviewImage} isVisible={isVisiblePreviewModal} setIsVisible={setIsVisiblePreviewModal} />

            <DeleteModalWeb 
                modalTitle="Confirm discharge"
                modalBody="Are you sure you want to confirm departure?"
                primaryButton="Confirm"
                handleDelete={handleLeave} 
                isVisible={isVisibleLeave} 
                setIsVisible={setIsVisibleLeave} />
        </div>
    );
}

export default ShelterDetails;
import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const DisasterRegister = () => {
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [disasterType1, setDisasterType1] = useState(null)
    const [disasterType2, setDisasterType2] = useState(null)
    const [name, setName] = useState('')
    const [memo, setMemo] = useState('')
    const [startDate, setStartDate] = useState('')
    const [occurBasisUrl, setOccurBasisUrl] = useState('')
    const [selectedAreas, setSelectedAreas] = useState([])
    
    const [startDateError, setStartDateError] = useState(null)
    
    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)

    const [disasterTypes1, setDisasterTypes1] = useState([])
    const [disasterTypes2, setDisasterTypes2] = useState([])
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D001&codeDepth=1', setDisasterTypes1)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if (!disasterType1) return
        setDisasterType2(null)

        ApiHelper.loadDropdown('groupCode=D001&codeDepth=2&codeLev1='+disasterType1, setDisasterTypes2)
    }, [disasterType1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        ValidatorHelper.validateYYYYMMDD(startDate, setStartDateError)
    }, [startDate])

    const handleAddArea = () => {
        setSelectedAreas(current => {
            if (current.find(value => value.area1.value == area1?.value && value.area2.value == area2?.value))
            {
                setArea1(null)
                setArea2(null)
                return current
            }
            else
            {
                setArea1(null)
                setArea2(null)
                return [...current, {area1: area1, area2: area2}]
            }
        })
    }

    const handleRemoveArea = (area) => {
        setSelectedAreas(current => current.filter(value => !(value.area1.value == area?.area1?.value && value.area2.value == area?.area2?.value)))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidYYYYMMDD(startDate))
        {
            setError('Enter valid start date in YYYYMMDD')
            return
        }
        if (selectedAreas.length == 0)
        {
            setError('Select at least 1 affected area')
            return
        }

        setIsLoadingSubmit(true)
        
        var regionList = selectedAreas.map(value => ({area1Cd: value?.area1?.value, area2Cd: value?.area2?.value}))
        
        var formData = {
            type1Cd: disasterType1,
            type2Cd: disasterType2.substring(disasterType1.length),
            name: name,
            memo: memo,
            startDt: startDate,
            occurBasisUrl: occurBasisUrl,
            regionList: regionList,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/cfg/regDisasterCdInfo', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setDisasterType1(null)
                setDisasterType2(null)
                setName('')
                setMemo('')
                setStartDate('')
                setOccurBasisUrl('')
                setSelectedAreas([])
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">System management</h3>
                <Link to={'/statistics'} className="sidebar-link">Statistics</Link>
                <Link to={'/user-management'} className="sidebar-link">User management</Link>
                <Link to={'/disaster-management'} className="sidebar-link active">Disaster management</Link>
                <Link to={'/notices-news'} className="sidebar-link">Notices/News</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Disaster registration</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <Link to={'/disaster-management'} className="dms-btn dms-btn-s dms-btn-outline mr">Close</Link>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoadingSubmit ? 'disabled' : ''}`}>Register</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>Disaster information</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Disaster code</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Auto generated" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Disaster Type<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        required={true}
                                        placeholder={'Select Category 1'}
                                        value={disasterTypes1 ? disasterTypes1.find(value => value.value == disasterType1) ?? null : null}
                                        onChange={(e) => setDisasterType1(e.value)}
                                        options={disasterTypes1}
                                    />
                                </div>
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        required={true}
                                        placeholder={'Select Category 2'}
                                        value={disasterTypes2 ? disasterTypes2.find(value => value.value == disasterType2) ?? null : null}
                                        onChange={(e) => setDisasterType2(e.value)}
                                        options={disasterTypes2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Disaster Name<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        placeholder="Please enter a name"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Occurance date<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setStartDate(e.target.value)}
                                        value={startDate}
                                        className={startDateError ? 'is-invalid' : ''}
                                        placeholder="Please enter numbers only (YYYYMMDD)"
                                        required={true} />
                                    {startDateError ? <div className="input-helper-error">{startDateError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">Occurance rationale</div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setOccurBasisUrl(e.target.value)}
                                        value={occurBasisUrl}
                                        placeholder="Please enter value" />
                                </div>
                                <div className="dms-input-4">
                                    <a href={occurBasisUrl ?? null} onClick={(e) => {if (!occurBasisUrl) e.preventDefault()}} target="_blank" className={`dms-btn dms-btn-s dms-btn-outline align-self-center ${!occurBasisUrl ? 'disabled' : ''}`}>Go to link</a>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label">Disaster description<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setMemo(e.target.value)}
                                        value={memo}
                                        placeholder="Please enter value"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading">
                            <h5>Occurance Region</h5>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Affect Area<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'Select Province'}
                                        value={area1 ?? null}
                                        onChange={(e) => setArea1(e)}
                                        options={areas1}
                                    />
                                </div>
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'Select District'}
                                        value={area2 ?? null}
                                        onChange={(e) => setArea2(e)}
                                        options={areas2}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <button type="button" onClick={handleAddArea} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />Add</button>
                                </div>
                            </div>
                        </div>

                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-100">
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>Province</th>
                                                <th>District</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {selectedAreas.map(selectedArea => (
                                            <tr key={selectedArea.area2.value}>
                                                <td>{selectedArea.area1.label}</td>
                                                <td>{selectedArea.area2.label}</td>
                                                <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveArea(selectedArea)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">Remove</button></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                        </div>
                    </div>
                </form>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default DisasterRegister;
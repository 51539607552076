import { useEffect, useState } from "react";
import { Alert, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const UserRegister = () => {
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [birthday, setBirthday] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [affiliation, setAffiliation] = useState('')

    const [birthdayError, setBirthdayError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    
    const [affiliations, setAffiliations] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D009&codeDepth=1', setAffiliations)
    }, [])

    useEffect(() => {
        ValidatorHelper.validateYYYYMMDD(birthday, setBirthdayError)
    }, [birthday])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password != passwordConfirm)
            {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm)
        {
            setPasswordError('Both password must match')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password))
        {
            setError('Password must contain at least 8 characters including alphanumeric and special characters')
            return
        }
        if (password != passwordConfirm)
        {
            setError('Both password must match')
            return
        }
        if (!ValidatorHelper.isValidYYYYMMDD(birthday))
        {
            setError('Enter valid birthday in YYYYMMDD')
            return
        }
        if (!zipCode)
        {
            setError('Enter choose an address')
            return
        }

        setIsLoadingSubmit(true)
        
        var formData = {
            typeCd: "02",
            userId: userId,
            name: name,
            pwd: password,
            bday: birthday,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: email,
            affiliationCd: affiliation
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/regUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setName('')
                setUserId('')
                setPassword('')
                setPasswordConfirm('')
                setBirthday('')
                setZipCode('')
                setMainAddress('')
                setDetailedAddress('')
                setPhoneNumber('')
                setEmail('')
                setAffiliation('')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">System management</h3>
                <Link to={'/statistics'} className="sidebar-link">Statistics</Link>
                <Link to={'/user-management'} className="sidebar-link active">User management</Link>
                <Link to={'/disaster-management'} className="sidebar-link">Disaster management</Link>
                <Link to={'/notices-news'} className="sidebar-link">Notices/News</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">User registration</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoadingSubmit ? 'disabled' : ''}`}>Register</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>Personal information</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        placeholder="Please enter a name"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">ID<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setUserId(e.target.value)}
                                        value={userId}
                                        placeholder="Please enter ID"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">Password<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        className={passwordError ? 'is-invalid' : ''}
                                        placeholder="8 characters including alphanumeric and special characters"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setPasswordConfirm(e.target.value)}
                                        value={passwordConfirm}
                                        className={passwordError ? 'is-invalid' : ''}
                                        placeholder="Confirm your password"
                                        required={true} />
                                    {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Birthday</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setBirthday(e.target.value)}
                                        value={birthday}
                                        className={birthdayError ? 'is-invalid' : ''}
                                        placeholder="Please enter numbers only (YYYYMMDD)" />
                                    {birthdayError ? <div className="input-helper-error">{birthdayError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">Address</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        value={zipCode}
                                        placeholder="Please choose address"
                                        disabled={true} />
                                </div>
                                <div className="dms-input-4">
                                    <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        value={mainAddress}
                                        placeholder="Please choose address"
                                        disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setDetailedAddress(e.target.value)}
                                        value={detailedAddress}
                                        placeholder="Enter detailed address"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Phone number<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        value={phoneNumber}
                                        placeholder="Please enter phone number"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Email<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        placeholder="Please enter email"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Affiliation<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        required={true}
                                        placeholder={'Select an affiliation'}
                                        value={affiliations ? affiliations.find(value => value.value == affiliation) ?? null : null}
                                        onChange={(e) => setAffiliation(e.value)}
                                        options={affiliations}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                    
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />
        </div>
    );
}
 
export default UserRegister;
import { useState } from "react";
import { Modal, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { SlBell } from "react-icons/sl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { store } from "../redux/store";
import { removeUser } from "../redux/userSlice";
import { useSelector } from "react-redux";

const victimsScreens = [ 'victim-management', 'victim-details', 'victim-register', 'find-missing-children', 'register-missing-children', 'find-missing-children-details', 'find-missing-parent-details', 'edit-missing-children', 'edit-missing-parent' ]
const reliefScreens = [ 'inventory-management', 'inventory-details', 'transfer-supplies', 'warehouse-management', 'warehouse-details', 'warehouse-register', 'incoming-supplies', 'outgoing-supplies', 'requests-for-supplies', 'register-for-supplies', 'requests-for-supplies-details-1', 'requests-for-supplies-details-2' ]
const shelterScreens = [ 'shelter-management', 'shelter-register', 'shelter-details', 'shelter-supplies-register', 'shelter-volunteer-register', 'shelter-inquiry-board', 'shelter-inquiry-details-1', 'shelter-inquiry-details-2', 'shelter-inquiry-details-3' ]
const volunteerScreens = [ 'volunteer-management', 'volunteer-details', 'volunteer-register', 'volunteer-assignment', 'volunteer-requests', 'volunteer-requests-details-1', 'volunteer-requests-details-2', 'volunteer-requests-register' ]
const systemScreens = [ 'statistics', 'user-management', 'user-register', 'user-details', 'disaster-management', 'disaster-register', 'disaster-details', 'notices-news', 'notices-news-register', 'notices-news-details', 'notices-news-preview' ]

const Header = () => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    const currentPath = useLocation().pathname.split('/')[1];
    const [isVisibleLogout, setIsVisibleLogout] = useState(false)

    const handleLogout = (e) => {
        e.preventDefault()
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/logout?userId='+user?.userId, {
            method: 'POST',
            // headers: {
            //     "Authorization": "Bearer "+localStorage.getItem("token")
            // }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }
            
            return response.json();
        })
        store.dispatch(removeUser());
        navigate('/login');
    }

    return (
        <>
            <Navbar expand="xl">
                <div className="container-fluid">
                    <Navbar.Brand as={Link} to={'/'}><img src="/images/logo.png"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link as={Link} to={'/'} className="mx-xl-2">Dashboard</Nav.Link>
                            <NavDropdown className={`mx-xl-2 ${victimsScreens.includes(currentPath) ? 'active' : ''}`} title="Victims" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/victim-management'}>Victim management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/find-missing-children'}>Find missing child</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${shelterScreens.includes(currentPath) ? 'active' : ''}`} title="Shelter" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/shelter-management'}>Shelter management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/shelter-inquiry-board'}>Shelter inquiry board</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${reliefScreens.includes(currentPath) ? 'active' : ''}`} title="Relief supplies" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/inventory-management'}>Inventory management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/warehouse-management'}>Warehouse management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/incoming-supplies'}>Incoming supplies</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/outgoing-supplies'}>Outgoing supplies</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/requests-for-supplies'}>Requests for supplies</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${volunteerScreens.includes(currentPath) ? 'active' : ''}`} title="Volunteer" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/volunteer-management'}>Volunteers management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/volunteer-assignment'}>Volunteers assignment</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/volunteer-requests'}>Requests for volunteers</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${systemScreens.includes(currentPath) ? 'active' : ''}`} title="System management" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/statistics'}>Statistics</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/user-management'}>User management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/disaster-management'}>Disaster management</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/notices-news'}>Notices/News</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#home">{user?.userName}</Nav.Link>
                            <Nav.Link onClick={() => setIsVisibleLogout(true)} className="p-0 mx-xl-2 my-1"><div className="header-logout">Logout</div></Nav.Link>
                            {/* <Nav.Link href="#home" className="header-badge"><SlBell size="24px" /><div className="badge-count">0</div></Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>

            <Modal show={isVisibleLogout} onHide={() => setIsVisibleLogout(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Logout Check</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to logout?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleLogout(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={handleLogout} className="dms-btn dms-btn-xs dms-btn-filled-dark">Logout</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Header;

import { useState, useEffect } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoBandage, IoChevronUp, IoClose, IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import { IoMdHeart } from "react-icons/io";
import { FaWheelchair } from "react-icons/fa6";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const AdminShelterBelongVictimsInShelter = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMLst'
    
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);

    const [parameters, setParameters] = useState({
        facilityId: user.facilityId,
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleLoadMore = () => {
        setParameters(current => ({...current, reqPageSize: current.reqPageSize+10}))
    }

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <Link to="/shelter-admin-belong-m" className="mobile-navbar-left plalin-link">
                        <img src="/images/icons/back.svg" />
                    </Link>
                    <div className="mobile-navbar-title">Victims in shelter</div>
                    <div className="blank-icon" />
                </div>
            </div>
            
            <div className="content-container container flex-fill pt-0 pb-4">
                <p className="text-gray-03 fs-xs mb-n2 mt-3">This page's only the list of victims currently in the shelter will be output.</p>
                {items.map(item => (
                    <div key={item.userId} className="mobile-card">
                        <div className="d-flex align-items-center mb-2">
                            <p className="mobile-card-title d-block fw-bold text-black">{item.name}&nbsp;
                                {(item.noteCdList ?? []).includes('03') ? <span className="victim-tag tag-red"><IoMdHeart size="14px" /></span> : null}
                                {(item.noteCdList ?? []).includes('01') ? <span className="victim-tag tag-green"><IoBandage size="14px" /></span> : null}
                                {(item.noteCdList ?? []).includes('02') ? <span className="victim-tag tag-blue"><FaWheelchair size="14px" /></span> : null}
                            </p>
                            <Link to={"/shelter-admin-victims-in-shelter-details-belong-m/"+item.userId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                        </div>
                        <p className="mobile-card-text">{item.telNo}</p>
                        <p className="mobile-card-text">{item.wholeAddress}</p>
                        <p className="mobile-card-text">{item.regNo}</p>
                    </div>
                ))}
                
                {fetchIsLoading || (totalCount !== null && totalCount > parameters?.reqPageSize) ? <button type="button" onClick={handleLoadMore} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">{fetchIsLoading ? 'Loading data' : 'View more ('+parameters?.reqPageSize+'/'+totalCount+')'}</button> : null}
            </div>
            
            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}
 
export default AdminShelterBelongVictimsInShelter;
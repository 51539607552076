import React, { useState, useEffect } from "react";
import { Carousel, FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import ReactSelect from "react-select";
import Footer from "./Components/Footer";
import { IoCaretUp } from "react-icons/io5";
import { FaAngry } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { IoChevronDown, IoChevronUp, IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import GoogleMap from "./GoogleMap";
import KakaoMapMobile from "./KakaoMapMobile";
import FooterMobile from "./Components/FooterMobile";
import { MdLocationSearching } from "react-icons/md";
import MenuMobile from "./MenuMobile"
import { useSelector } from "react-redux";


const DashboardMobile = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)
    
    const [items, setItems] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false);
    const [isCarouselVisible, setIsCarouselVisible] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showErrors, setShowErrors] = useState(false)
    const carouselItems = 4;

    const handleToggle = () => {
        setIsCarouselVisible(!isCarouselVisible);
    };


    const handlePrevious = () => {
        setCarouselIndex(current => current == 0 ? 0 : current - 1)
    }

    const handleNext = () => {
        setCarouselIndex(current => current == carouselItems ? carouselItems : current + 1)
    }

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="content-container flex-fill pt-0 pb-2">
                <KakaoMapMobile />
            </div>

            <div className="map-floating-btn"><Link to={'/shelter-list-no-belong-m'} className="plain-link">View as a list</Link></div>
            <div className="map-floating-btn map-floating-btn-right"><MdLocationSearching size="20px" /></div>

            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}

export default DashboardMobile;
import { useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoChevronUp, IoClose, IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";

const AdminShelterBelongInquiriesAnswer = () => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleFullText, setIsVisibleFullText] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Answer to inquiry</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/close.svg" />
                    </div>
                </div>
            </div>
            
            {!isSuccess ? (
                <div className="content-container container flex-fill pt-0 pb-4">
                    <div className="mobile-card form-group">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <p className="d-inline-block bg-cool-gray text-gray-04 px-3 py-1 mb-0">Inquiry</p>
                            <span onClick={() => setIsVisibleFullText(current => !current)} className="text-gray-04">{isVisibleFullText ? <IoChevronUp size="20px" /> : <IoChevronDown size="20px" />}</span>
                        </div>
                        <div className={`${isVisibleFullText ? '' : 'text-truncate-3'}`}>
                            <p className="mb-1">A long text long text long text A long text long text long text A long text long text long text. A long text long text long text A long text long text long text. A long text long text long text A long text long text long text.</p>
                            <p className="mb-1">A long text long text long text A long text long text long text.</p>
                            <p className="mb-1">A long text long text long text.</p>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Author</div>
                        <FormControl placeholder="Test test" disabled />
                    </div>
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">Answer</div>
                        <FormControl as="textarea" rows="5" />
                        <div className="input-helper text-end">0/240</div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="container pt-4 pb-4 flex-fill text-center">
                        <img src="/images/gradient-check.svg" className="image-success-check mt-3" />
                        <p className="fs-m fw-bold mt-3">Success.</p>
                        <p>You can return to the list or you can view your registered pages directly.</p>
                        <button className="dms-btn dms-btn-m dms-btn-outline">Go page</button>
                    </div>
                </>
            )}
            
            <div className="sticky-bottom mobile-bottom-btn-container">
            {!isSuccess ? (
                    <button type="button" onClick={() => setIsSuccess(true)} className="dms-btn dms-btn-l dms-btn-filled">Register</button>
                ) : (
                    <button type="button" onClick={() => setIsSuccess(false)} className="dms-btn dms-btn-l dms-btn-filled">Confirm</button>
                )}
            </div>
        </div>
    );
}
 
export default AdminShelterBelongInquiriesAnswer;
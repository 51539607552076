import { useState, useEffect } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";


const VolunteerManagement = () => {
    const [selectedItems, setSelectedItems] = useState([])

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/vltr/getVltrLst';

    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [successDelete, setSuccessDelete] = useState(null);
    const [errorDelete, setErrorDelete] = useState(null);

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [facilityType, setFacilityType] = useState(null)

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])

    const [parameters, setParameters] = useState({
        typeCd: '02',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse) {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])


    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Volunteers</h3>
                <Link to={'/volunteer-management'} className="sidebar-link active">Volunteers management</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">Volunteers assignment</Link>
                <Link to={'/volunteer-requests'} className="sidebar-link">Requests for volunteers</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Volunteers management</h3>
                        <Link to="/volunteer-register" className="dms-btn dms-btn-s dms-btn-outline">Register</Link>
                    </div>
                    <div className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Registration Center/Name</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Finding'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'Finding', label: 'Finding' },
                                                { value: 'Shelter 1', label: 'Shelter 1' },
                                                { value: 'Shelter 2', label: 'Shelter 2' },
                                                { value: 'Shelter 3', label: 'Shelter 3' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-5">
                                        <FormControl placeholder="Please enter a name" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Date of birth</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-6">
                                        <FormControl placeholder="Please enter numbers only (YYYYMMDD)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Phone number</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-6">
                                        <FormControl placeholder="Please enter numbers only" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                            <button className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                        </div>
                    </div>
                    <div className="table-actions-container mb-20px">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="search-result">Search Result <span>{totalCount}</span> Items</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <ReactSelect
                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                    classNamePrefix="select"
                                    className="dropdown-sm mr"
                                    value={{ label: 'View By 10' }}
                                    isSearchable={false}
                                    options={[
                                        { value: 'View By 10', label: 'View By 10' },
                                        { value: 'View By 20', label: 'View By 20' },
                                        { value: 'View By 30', label: 'View By 30' },
                                    ]}
                                />
                                <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                            </div>
                        </div>
                    </div>
                    <table className="table dms-table-seperate">
                        <thead>
                            <tr>
                                <th>Registration Center</th>
                                <th>Name</th>
                                <th>Date of birth</th>
                                <th>Phone number</th>
                                <th>Email address</th>
                                <th>View Detail</th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.map(item => (
                                <tr key={item.userId}>
                                <td>{item.vltrRegCenterName}</td>
                                    <td>{item.name}</td>
                                    <td>{item.bday}</td>
                                    <td>{item.telNo}</td>
                                    <td>{item.email}</td>
                                    <td style={{ width: "120px" }}><Link to={"/volunteer-details"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount / parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                            forcePage={Math.max(parameters.reqPageNo - 1, 0)}
                            pageCount={Math.ceil(totalCount / parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>

                <Footer />
            </div>

            <SaveExcelModal isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}

export default VolunteerManagement;
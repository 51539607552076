import { useEffect, useState } from "react";
import { Alert, FormControl } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";
import { format } from "date-fns";

const DisasterDetails = () => {
    const { id } = useParams();
    
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdInfo?disasterCd='+id
    const areasApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterAreaLst?reqPageSize=1000&reqPageNo=1&disasterCd='+id
    
    const [isVisibleDelete, setIsVisibleDelete] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoadingArea, setIsLoadingArea] = useState(false);
    const [successArea, setSuccessArea] = useState(null);
    const [errorArea, setErrorArea] = useState(null);

    const [disasterType1, setDisasterType1] = useState(null)
    const [disasterType2, setDisasterType2] = useState(null)
    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [memo, setMemo] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [occurBasisUrl, setOccurBasisUrl] = useState('')
    const [selectedAreas, setSelectedAreas] = useState([])
    const [status, setStatus] = useState('')
    const [disasterEnded, setDisasterEnded] = useState(false)

    const [startDateError, setStartDateError] = useState(null)
    const [endDateError, setEndDateError] = useState(null)
    
    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    const { fetchResponse: areasFetchResponse } = useFetch(areasApiUrl);

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setCode(fetchResponse?.resultObject?.disasterCd)
            setName(fetchResponse?.resultObject?.name)
            setMemo(fetchResponse?.resultObject?.memo)
            setStartDate(fetchResponse?.resultObject?.startDt)
            setEndDate(fetchResponse?.resultObject?.endDt)
            if (fetchResponse?.resultObject?.endDt) setDisasterEnded(true)
            setOccurBasisUrl(fetchResponse?.resultObject?.occurBasisUrl)
            setStatus(fetchResponse?.resultObject?.status)
            setDisasterType1(fetchResponse?.resultObject?.type1Cd)
            setDisasterType2(fetchResponse?.resultObject?.type1Cd+fetchResponse?.resultObject?.type2Cd)
        }
    }, [fetchResponse])

    useEffect(() => {
        if (areasFetchResponse?.resultList)
        {
            setSelectedAreas(areasFetchResponse?.resultList?.map(value => ({
                area1: {
                    value: value.area1Cd, 
                    label: value.area1,
                },
                area2: {
                    value: value.area2Cd, 
                    label: value.area2,
                }
            })))
        }
    }, [areasFetchResponse])

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)

    const [disasterTypes1, setDisasterTypes1] = useState([])
    const [disasterTypes2, setDisasterTypes2] = useState([])
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D001&codeDepth=1', setDisasterTypes1)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if (!disasterType1) return
        
        ApiHelper.loadDropdown('groupCode=D001&codeDepth=2&codeLev1='+disasterType1, setDisasterTypes2)
    }, [disasterType1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        ValidatorHelper.validateYYYYMMDD(startDate, setStartDateError)
    }, [startDate])

    useEffect(() => {
        ValidatorHelper.validateYYYYMMDD(endDate, setEndDateError)
    }, [endDate])

    const handleAddArea = () => {
        if (selectedAreas.find(value => value.area1.value == area1?.value && value.area2.value == area2?.value))
        {
            setArea1(null)
            setArea2(null)
            return
        }
        
        setErrorArea(null)
        setSuccessArea(null)
        setIsLoadingArea(true)
        
        var formData = {
            area1Cd: area1?.value,
            area2Cd: area2?.value,
            disasterCode: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/cfg/regDisasterArea', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessArea(data.responseMessage ?? 'Success')

                setArea1(null)
                setArea2(null)
                setSelectedAreas(current => [...current, {area1: area1, area2: area2}])
            }
            else
            {
                setErrorArea(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingArea(false);
        })
        .catch(error => {
            setErrorArea(error.message);
            setIsLoadingArea(false);
        })
    }

    const handleRemoveArea = (area) => {
        setErrorArea(null)
        setSuccessArea(null)
        setIsLoadingArea(true)
        
        var formData = {
            area1Cd: area?.area1?.value,
            area2Cd: area?.area2?.value,
            disasterCode: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/cfg/delDisasterArea', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessArea(data.responseMessage ?? 'Success')

                setSelectedAreas(current => current.filter(value => !(value.area1.value == area?.area1?.value && value.area2.value == area?.area2?.value)))
            }
            else
            {
                setErrorArea(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingArea(false);
        })
        .catch(error => {
            setErrorArea(error.message);
            setIsLoadingArea(false);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidYYYYMMDD(startDate))
        {
            setError('Enter valid start date in YYYYMMDD')
            return
        }
        if (endDate && !ValidatorHelper.isValidYYYYMMDD(endDate))
        {
            setError('Enter valid end date in YYYYMMDD')
            return
        }

        setIsLoading(true)
        
        var formData = {
            disasterCd: id,
            name: name,
            memo: memo,
            startDt: startDate,
            endDt: endDate,
            occurBasisUrl: occurBasisUrl,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/cfg/updateDisasterCd', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                if (endDate) setDisasterEnded(true)
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleDelete = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)
        
        var formData = {
            delDisasterCd: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/cfg/delDisasterCd', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setDisasterType1(null)
                setDisasterType2(null)
                setName('')
                setMemo('')
                setStartDate('')
                setOccurBasisUrl('')
                setSelectedAreas([])
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">System management</h3>
                <Link to={'/statistics'} className="sidebar-link">Statistics</Link>
                <Link to={'/user-management'} className="sidebar-link">User management</Link>
                <Link to={'/disaster-management'} className="sidebar-link active">Disaster management</Link>
                <Link to={'/notices-news'} className="sidebar-link">Notices/News</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Disaster details</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">Modified: {fetchResponse?.resultObject?.updateDt}</p>
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                            <button type="button" onClick={() => setIsVisibleDelete(true)} className={`dms-btn dms-btn-s dms-btn-outline mr ${isLoading ? 'disabled' : ''}`}>Remove</button>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline ${isLoading ? 'disabled' : ''}`}>Modify</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>Disaster information</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Disaster code</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        value={code}
                                        placeholder="Auto generated"
                                        disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Disaster Type<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        isDisabled={true}
                                        placeholder={'Select Category 1'}
                                        value={disasterTypes1 ? disasterTypes1.find(value => value.value == disasterType1) ?? null : null}
                                        onChange={(e) => setDisasterType1(e.value)}
                                        options={disasterTypes1}
                                    />
                                </div>
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        isDisabled={true}
                                        placeholder={'Select Category 2'}
                                        value={disasterTypes2 ? disasterTypes2.find(value => value.value == disasterType2) ?? null : null}
                                        onChange={(e) => setDisasterType2(e.value)}
                                        options={disasterTypes2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Disaster Name<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        placeholder="Please enter a name"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Occurance date<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        disabled={true}
                                        value={startDate}
                                        className={startDateError ? 'is-invalid' : ''}
                                        placeholder="Please enter numbers only (YYYYMMDD)"
                                        required={true} />
                                    {startDateError ? <div className="input-helper-error">{startDateError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">End date<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setEndDate(e.target.value)}
                                        disabled={disasterEnded}
                                        value={endDate}
                                        className={endDateError ? 'is-invalid' : ''}
                                        placeholder="Please enter numbers only (YYYYMMDD)" />
                                    {endDateError ? <div className="input-helper-error">{endDateError}</div> : null}
                                </div>
                                <div className="dms-input-4">
                                    <button type="button" onClick={() => {
                                        if (disasterEnded) return
                                        setEndDate(format(new Date(), 'yyyyMMdd'))
                                    }} className={`dms-btn dms-btn-s dms-btn-outline align-self-center ${disasterEnded ? 'disabled' : ''}`}>End</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">Occurance rationale</div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setOccurBasisUrl(e.target.value)}
                                        value={occurBasisUrl}
                                        placeholder="Please enter value" />
                                </div>
                                <div className="dms-input-4">
                                    <a href={occurBasisUrl ?? null} onClick={(e) => {if (!occurBasisUrl) e.preventDefault()}} target="_blank" className={`dms-btn dms-btn-s dms-btn-outline align-self-center ${!occurBasisUrl ? 'disabled' : ''}`}>Go to link</a>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label">Disaster description<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setMemo(e.target.value)}
                                        value={memo}
                                        placeholder="Please enter value"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading">
                            <h5>Occurance Region</h5>
                        </div>
                        
                        {successArea ? <Alert variant="success">{successArea ?? 'Success'}</Alert> : null}
                        {errorArea ? <Alert variant="danger">{errorArea ?? 'Error'}</Alert> : null}
                        
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Affect Area<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'Select Province'}
                                        value={area1 ?? null}
                                        onChange={(e) => setArea1(e)}
                                        options={areas1}
                                    />
                                </div>
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'Select District'}
                                        value={area2 ?? null}
                                        onChange={(e) => setArea2(e)}
                                        options={areas2}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <button type="button" onClick={handleAddArea} className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center ${isLoadingArea ? 'disabled' : ''}`}><GoPlusCircle size="22px" />Add</button>
                                </div>
                            </div>
                        </div>

                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-100">
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>Province</th>
                                                <th>District</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {selectedAreas.map(selectedArea => (
                                            <tr key={selectedArea.area2.value}>
                                                <td>{selectedArea.area1.label}</td>
                                                <td>{selectedArea.area2.label}</td>
                                                <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveArea(selectedArea)} className={`dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3 ${isLoadingArea ? 'disabled' : ''}`}>Remove</button></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                        </div>
                    </div>
                </form>

                <DeleteModalWeb handleDelete={handleDelete} isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default DisasterDetails;
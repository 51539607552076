import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { SlBell } from "react-icons/sl";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div className="row">
                <div className="col-lg-auto me-auto col-lg-logo1">
                    <img className="footer-logo" src="/images/logo.png"/>
                </div>
                <div className="col-lg-auto col-lg-links1">
                    <div className="row row-cols-lg-auto">
                        <div className="col">
                            <p className="footer-heading">Victims</p>
                            <Link to={'/victim-management'} className="footer-link">Victim management</Link>
                            <Link to={'/find-missing-children'} className="footer-link">Find missing child</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">Shelter</p>
                            <Link to={'/shelter-management'} className="footer-link">Shelter management</Link>
                            <Link to={'/shelter-inquiry-board'} className="footer-link">Shelter inquiry board</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">Relief supplies</p>
                            <Link to={'/inventory-management'} className="footer-link">Inventory management</Link>
                            <Link to={'/warehouse-management'} className="footer-link">Warehouse management</Link>
                            <Link to={'/incoming-supplies'} className="footer-link">Incoming supplies</Link>
                            <Link to={'/outgoing-supplies'} className="footer-link">Outgoing supplies</Link>
                            <Link to={'/requests-for-supplies'} className="footer-link">Requests for supplies</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">Volunteer</p>
                            <Link to={'/volunteer-management'} className="footer-link">Volunteers management</Link>
                            <Link to={'/volunteer-assignment'} className="footer-link">Volunteers assignment</Link>
                            <Link to={'/volunteer-requests'} className="footer-link">Requests for volunteers</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">System management</p>
                            <Link to={'/statistics'} className="footer-link">Statistics</Link>
                            <Link to={'/user-management'} className="footer-link">User management</Link>
                            <Link to={'/disaster-management'} className="footer-link">Disaster management</Link>
                            <Link to={'/notices-news'} className="footer-link">Notices/News</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

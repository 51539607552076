import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";

const WarehouseManagement = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getFACLst';

    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])

    const [parameters, setParameters] = useState({
        typeCd: '01',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...filters,
            ...(area1?.value ? {area1Cd: area1.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
        }))
    }

    const initializeFilter = () => {
        setFilters({});
        setArea2(null)
        setParameters(current => ({typeCd: '01', reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if(areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/expFacLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Relief supplies</h3>
                <Link to={'/inventory-management'} className="sidebar-link">Inventory management</Link>
                <Link to={'/warehouse-management'} className="sidebar-link active">Warehouse management</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">Incoming supplies</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">Outgoing supplies</Link>
                <Link to={'/requests-for-supplies'} className="sidebar-link">Requests for supplies</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Warehouse management</h3>
                        <Link to="/warehouse-register" className="dms-btn dms-btn-s dms-btn-outline">Register</Link>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Select a region/shelter</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'Select Province'}
                                            value={area1 ?? null}
                                            onChange={(e) => setArea1(e)}
                                            options={areas1}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'Entire city'}
                                            value={area2 ?? null}
                                            onChange={(e) => setArea2(e)}
                                            options={areas2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                        </div>
                    </form>

                    {totalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                    {totalCount !== 0 ? (
                        <div className="table-actions-container mb-20px">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    { fetchError ? <p className="search-result text-orange">{fetchError}</p> : null }
                                    { fetchIsLoading ? <p className="search-result">Loading data</p> : 
                                        totalCount !== null ? <p className="search-result">Search Result <span>{totalCount}</span> Items</p> : <p className="search-result">&nbsp;</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        value={{label: 'View By '+parameters.reqPageSize}}
                                        onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                        isSearchable={false}
                                        options={[
                                            { value: 10, label: 'View By 10' },
                                            { value: 20, label: 'View By 20' },
                                            { value: 30, label: 'View By 30' },
                                        ]}
                                    />
                                    <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    { totalCount !== null && totalCount !== 0 ? (
                        <table className="table dms-table-seperate">
                            <thead>
                                <tr>
                                    <th>Organization Classification</th>
                                    <th>Province</th>
                                    <th>Distict</th>
                                    <th>Address</th>
                                    <th>Warehouse</th>
                                    <th>Administrator</th>
                                    <th>Phone number</th>
                                    <th>View Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={item.facilityId}>
                                        <td>{item.classCdName}</td>
                                        <td>{item.area1}</td>
                                        <td>{item.area2}</td>
                                        <td>{item.addr}</td>
                                        <td>{item.name}</td>
                                        <td>{item.reprMgrName}</td>
                                        <td>{item.ownerTelno}</td>
                                        <td style={{ width: "120px" }}><Link to={"/warehouse-details/"+item.facilityId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({...current, reqPageNo: e.selected+1}))}
                            forcePage={Math.max(parameters.reqPageNo-1, 0)}
                            pageCount={Math.ceil(totalCount/parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>
                    
                <Footer />
            </div>

            <SaveExcelModal handleSaveExcel={handleSaveExcel} isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}
 
export default WarehouseManagement;
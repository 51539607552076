import { useRef, useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const VolunteerRequestsRegister = () => {
    const uploadFileRef = useRef()

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [image, setImage] = useState(false)

    const handleImageChange = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            setImage(reader.result)
            if (uploadFileRef?.current)
            {
                uploadFileRef.current.value = '';
            }
        }
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Volunteers</h3>
                <Link to={'/volunteer-management'} className="sidebar-link">Volunteers management</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">Volunteers assignment</Link>
                <Link to={'/volunteer-requests'} className="sidebar-link active">Requests for volunteers</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Register a volunteer request</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Register</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>Detailed information</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Requested By<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Assigned Relief Station<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Ulsan'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Ulsan', label: 'Ulsan'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Citywide'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Citywide', label: 'Citywide'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Select the shelter or warehouse'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Select the shelter or warehouse', label: 'Select the shelter or warehouse'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Phone number</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Number of volunteer request<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <div className="input-group">
                                        <FormControl placeholder="Enter quantity" />
                                        <span className="input-group-text">EA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">Request roles</div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Admissions support'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Admissions support', label: 'Admissions support'},
                                            { value: 'Female', label: 'Female'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-1">Role description</div>
                            <div className="dms-input-row">
                                <div className="dms-input-12">
                                    <FormControl as="textarea" rows="8" />  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default VolunteerRequestsRegister;
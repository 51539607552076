import React, { useState, useEffect, useRef } from 'react';
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import { GoArrowLeft } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import { useSelector } from "react-redux";
import { store } from "../redux/store";
import { setUser } from "../redux/userSlice";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const EditInformationVictim = () => {
    const { user } = useSelector(state => state.user);
    const [currentStep, setCurrentStep] = useState(1)
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isAdd1, setIsAdd1] = useState(false)
    const [isAdd2, setIsAdd2] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [noteCdText, setNotCdText] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [socialNumber, setSocialNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [headHousehold, setHeadHousehold] = useState('')
    const [specialNotes, setSpecialNotes] = useState([])
    const [infants, setInfants] = useState([])

    const [specialNoteCategory, setSpecialNoteCategory] = useState(null)
    const [specialNoteCategory1, setSpecialNoteCategory1] = useState(null)
    const [specialNoteSymptom, setSpecialNoteSymptom] = useState('')
    const [infantName, setInfantName] = useState('')
    const [infantSocialNumber, setInfantSocialNumber] = useState('')

    const [passwordError, setPasswordError] = useState(null)

    const [specialNoteCategories, setSpecialNoteCategories] = useState([])


    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/victim/getVCTMInfo?userId=${user.userId}`;

    useEffect(() => {
        if (!apiUrl) return;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setUserId(resultObject.userId);
                setName(resultObject.name);
                setSocialNumber(resultObject.regNo);
                setPhoneNumber(resultObject.telNo);
                setZipCode(resultObject.zipCd);
                setMainAddress(resultObject.addr);
                setDetailedAddress(resultObject.addrDtl);
                setHeadHousehold(resultObject.hshdName);
                const existingSpecialNotes = resultObject.victimNoteList.map(note => ({
                    id: note.noteId,
                    noteCd: note.noteCd,
                    note: note.noteCdText,
                    symptom: note.symptom
                }));
                setSpecialNotes(existingSpecialNotes);
                const existingInfants = resultObject.infantsList.map(infant => ({
                    id: infant.infantId,
                    name: infant.name,
                    regNo: infant.regNo
                }));
                setInfants(existingInfants);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl]);

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D004&codeDepth=1', setSpecialNoteCategories)
    }, [])

    useEffect(() => {
        if (isHeadHousehold) {
            setHeadHousehold(name)
        }
    }, [name])

    useEffect(() => {
        if (isHeadHousehold) {
            setHeadHousehold(name)
        }
    }, [isHeadHousehold])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError)) {
            if (password != passwordConfirm) {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm) {
            setPasswordError('Both password must match')
        }
        else {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleAddSpecialNotes = () => {
        if (specialNoteCategory && specialNoteSymptom) {
            setSpecialNotes(current => [...current, { id: Math.random().toString(36).substring(2, 10), noteCd: specialNoteCategory.value, note: specialNoteCategory.label, symptom: specialNoteSymptom }])
            setSpecialNoteCategory(null)
            setSpecialNoteSymptom('')
        }
    }

    const handleRemoveSpecialNotes = (item) => {
        setSpecialNotes(current => current.filter(value => value.id != item.id))
    }

    const handleAddInfant = () => {
        if (infantName && infantSocialNumber) {
            setInfants(current => [...current, { id: Math.random().toString(36).substring(2, 10), name: infantName, regNo: infantSocialNumber }])
            setInfantName('')
            setInfantSocialNumber('')
        }
    }

    const handleRemoveInfant = (item) => {
        setInfants(current => current.filter(value => value.id != item.id))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password)) {
            setError('Password must contain at least 8 characters including alphanumeric and special characters')
            return
        }
        if (password != passwordConfirm) {
            setError('Both password must match')
            return
        }
        if (!zipCode) {
            setError('Enter choose an address')
            return
        }

        setIsLoadingSubmit(true)

        var infantsList = infants.map(value => ({ name: value.name, regNo: value.regNo }))
        var victimNoteList = specialNotes.map(value => ({ noteCd: value.noteCd, symptom: value.symptom }))

        var formData = {
            userId: userId,
            pwd: password,
            name: name,
            bday: "",
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: "",
            typeCd: "02",
            regNo: socialNumber,
            hshdName: headHousehold,
            hshdYn: isHeadHousehold ? 'Y' : 'N',
            outYn: "N",
            delYn: "N",
            infantsList: infantsList,
            victimNoteList: victimNoteList,
        }

        fetch(process.env.REACT_APP_BASE_API_URL + '/victim/updateVCTM', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')



                    // setName('')
                    // setUserId('')
                    // setPassword('')
                    // setPasswordConfirm('')
                    // setSocialNumber('')
                    // setPhoneNumber('')
                    // setZipCode('')
                    // setMainAddress('')
                    // setDetailedAddress('')
                    // setHeadHousehold('')
                    // setSpecialNotes([])
                    // setInfants([])
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');

                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            })
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <form onSubmit={handleSubmit}>
                <div className="sticky-top">
                    <div className="mobile-navbar">
                        <div className="blank-icon" />
                        <div className="mobile-navbar-title">Edit information</div>
                        <div className="mobile-navbar-right">
                            <img src="/images/icons/close.svg" />
                        </div>
                    </div>
                </div>

                <div className="content-container container flex-fill pb-4">
                    <div className="form-heading"><h5>Basic information</h5></div>

                    <div className="content-container container flex-fill pb-4">
                        <div>
                            {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                            {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                <FormControl
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    placeholder="Please enter a name"
                                    required={true} />
                            </div>

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">ID<span className="label-ast">*</span></div>
                                <FormControl
                                    onChange={e => setUserId(e.target.value)}
                                    value={userId}
                                    placeholder="Please enter ID"
                                    required={true} />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Password<span className="label-ast">*</span></div>
                                <FormControl
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    className={passwordError ? 'is-invalid' : ''}
                                    placeholder="8 characters including alphanumeric and special characters"
                                    required={true} />

                                <FormControl
                                    onChange={e => setPasswordConfirm(e.target.value)}
                                    value={passwordConfirm}
                                    className={passwordError ? 'is-invalid' : ''}
                                    placeholder="Confirm your password"
                                    required={true} />
                                {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                            </div>

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Social Number<span className="label-ast">*</span></div>
                                <div className="d-flex align-items-center">
                                    <div className="flex-fill">
                                        <FormControl
                                            onChange={e => setSocialNumber(e.target.value)}
                                            value={socialNumber}
                                            placeholder="Please enter value"
                                            required={true} />

                                    </div>
                                    <div className="px-2">-</div>
                                    <div className="flex-fill">
                                        <FormControl placeholder="Back 7 digits" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Contact</div>
                                <FormControl
                                    onChange={e => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    placeholder="Please enter phone number"
                                    required={true} />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Address<span className="label-ast">*</span></div>
                                <div className="d-flex">
                                    <div className="flex-fill me-2">
                                        <FormControl
                                            value={zipCode}
                                            placeholder="Please choose address"
                                            disabled={true} />
                                    </div>
                                    <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl
                                    value={mainAddress}
                                    placeholder="Please choose address"
                                    disabled={true} />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl
                                    onChange={e => setDetailedAddress(e.target.value)}
                                    value={detailedAddress}
                                    placeholder="Enter detailed address"
                                    required={true} />
                            </div>
                            <div className="form-group mb-0">
                                <div className="dms-form-label align-self-center">Head of Household</div>
                                <div className="d-flex align-items-center">
                                    <div className="flex-fill me-2">
                                        <FormControl
                                            onChange={e => setHeadHousehold(e.target.value)}
                                            value={headHousehold}
                                            placeholder="Please enter name"
                                            required={true}
                                            disabled={isHeadHousehold} />
                                    </div>
                                    <div className="dms-input-4">
                                        <div
                                            className={`dms-chip-btn dms-chip-btn-s ${isHeadHousehold ? 'dms-chip-btn-active' : ''} align-self-center`}
                                            onClick={() => setIsHeadHousehold(current => !current)}>
                                            <FormCheck checked={true} />
                                            I'm Head of Household</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="mobile-section-separator" />
                        <div className="form-heading">
                            <h5>Enter special notes</h5>
                            <p>Write down medical conditions or pregnancy for better care.</p>
                        </div>
                        <div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Descriptions</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'Select'}
                                            value={specialNoteCategory ?? null}
                                            onChange={(e) => setSpecialNoteCategory(e)}
                                            options={specialNoteCategories}
                                        />
                                    </div>
                                    <div className="dms-input-7 mt-2">
                                        <FormControl
                                            onChange={e => setSpecialNoteSymptom(e.target.value)}
                                            value={specialNoteSymptom}
                                            placeholder="Please enter your unusual symptoms" />
                                    </div>
                                    <div className="dms-input-2 mt-2">
                                        <button type="button" onClick={handleAddSpecialNotes} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />Add More</button>
                                    </div>
                                </div>
                            </div>

                            {specialNotes.length > 0 ? (
                                <table className="table dms-table-1">
                                    <thead>
                                        <tr>
                                            <th>Special notes</th>
                                            <th>Description</th>
                                            <th>Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {specialNotes.map(specialNote => (
                                            <tr key={specialNote.id}>
                                                <td>{specialNote.note}</td>
                                                <td>{specialNote.symptom}</td>
                                                <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveSpecialNotes(specialNote)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">Remove</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>

                        <div className="mobile-section-separator" />

                        <div className="form-heading">
                            <h5>Add Infants and Children</h5>
                        </div>
                        <div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Infant Name</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <FormControl
                                            onChange={e => setInfantName(e.target.value)}
                                            value={infantName}
                                            placeholder="Please enter a name" />
                                    </div>
                                    <div className="dms-input-2 d-flex mt-2">
                                        <div className="dms-form-label align-self-center">Social Number</div>
                                    </div>
                                    <div className="dms-input-5">
                                        <FormControl
                                            onChange={e => setInfantSocialNumber(e.target.value)}
                                            value={infantSocialNumber}
                                            placeholder="Please enter value" />
                                    </div>
                                    <div className="dms-input-2 mt-2">
                                        <button type="button" onClick={handleAddInfant} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />Add More</button>
                                    </div>
                                </div>
                            </div>

                            {infants.length > 0 ? (
                                <table className="table dms-table-1">
                                    <thead>
                                        <tr>
                                            <th>Infant, Children's name</th>
                                            <th>Social Number</th>
                                            <th>Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {infants.map(infant => (
                                            <tr key={infant.id}>
                                                <td>{infant.name}</td>
                                                <td>{infant.regNo}</td>
                                                <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveInfant(infant)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">Remove</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                        <div className="sticky-bottom mobile-bottom-btn-container">
                            {/* <button type="submit" className={`dms-btn dms-btn-l dms-btn-filled ${isLoadingSubmit ? 'disabled' : ''}`}>Finish</button> */}
                            {/* <button type="submit"  className="dms-btn dms-btn-l dms-btn-filled" >Finish</button> */}
                        </div>
                    </div>

                    <div className="sticky-bottom mobile-bottom-btn-container">

                    </div>
                </div>

                <div className="sticky-bottom mobile-bottom-btn-container">
                    <button type="submit" className="dms-btn dms-btn-l dms-btn-filled">Modify</button>
                </div>

            </form>
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress}
                setIsVisible={setIsVisibleAddress}
                fullscreen={true} />
        </div>
    );
}

export default EditInformationVictim;